<template>
  <BartenderBar
    class="settings-bar"
    name="settings"
    position="right"
    mode="float"
  >
    <template #default="{ close }">
      <div class="settings-bar__wrap">
        <Block>
          <h2 class="h2--subtitle">{{ $t('settings.title') }}</h2>
          <HelpMenu />
        </Block>
        <Block class="copyright" bordered>
          <p class="copyright__text">
            v. {{ env.VUE_APP_VERSION }}<br />
            © {{ env.VUE_APP_BUILD_YEAR }}
            <a href="https://fokke.fi" rel="noreferrer"
              >Ville Fokke Saarivaara</a
            >
          </p>
          <p class="copyright__text">
            {{ $t('copyright.providedBy') }}
            <a href="https://www.radio-browser.info" rel="noreferrer"
              >Radio Browser</a
            >.
          </p>
          <p class="copyright__text">
            {{ $t('copyright.images') }}
          </p>
        </Block>
      </div>

      <PanelCloseButton @click="close" class="hide-desktop" />
    </template>
  </BartenderBar>
</template>

<script setup lang="ts">
import { BartenderBar } from '@fokke-/vue-bartender.js'

const env = process.env
</script>

<style lang="scss" scoped>
.settings-bar {
  &__wrap {
    padding: var(--block-spacing-vertical-normal) 0;
    min-height: 100%;
  }
}

.copyright {
  &__text {
    display: block;
    width: 100%;
    font-size: var(--font-size-small);

    a {
      color: var(--color-primary);
      text-underline-offset: 0.3em;

      &:hover,
      &:focus-visible {
        text-decoration: none;
      }
    }
  }
}
</style>
