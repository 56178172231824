<template>
  <Block>
    <div class="view-header">
      <div class="view-header__col view-header__col--content">
        <h1 v-if="finalTitle" class="view-header__title">
          {{ finalTitle }}
        </h1>
        <nav
          v-if="breadcrumbs.length > 1"
          class="breadcrumbs"
          :aria-label="$t('breadcrumbs.ariaLabel')"
        >
          <ol class="breadcrumbs__list">
            <li
              v-for="(item, index) of breadcrumbs"
              :key="item.name"
              class="breadcrumbs__list-item"
            >
              <span
                v-if="index === breadcrumbs.length - 1"
                class="breadcrumbs__item breadcrumbs__item--current"
                aria-current="page"
              >
                {{ item.title }}
              </span>

              <template v-else>
                <router-link
                  :to="{ name: item.name }"
                  class="breadcrumbs__item breadcrumbs__item--link"
                >
                  {{ item.title }}
                </router-link>
                <span class="breadcrumbs__separator" aria-hidden="true">
                  »
                </span>
              </template>
            </li>
          </ol>
        </nav>
      </div>
      <div
        v-if="!!slots.tools"
        class="view-header__col view-header__col--tools"
      >
        <slot name="tools" />
      </div>
    </div>
  </Block>
</template>

<script setup lang="ts">
import type { Breadcrumb } from '@/types'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    title?: string
  }>(),
  {
    title: undefined,
  },
)

const slots = defineSlots<{
  tools(): any
}>()

const route = useRoute()
const { t, te } = useI18n()

const finalTitle = computed<string | undefined>(() => {
  if (props.title) {
    return props.title
  }

  if (route.meta.title) {
    return String(route.meta.title)
  }

  const translationKey = `routes.${String(route.name)}.title`
  if (te(translationKey)) {
    return t(translationKey)
  }

  return undefined
})

const breadcrumbs = computed<Breadcrumb[]>(() => {
  return route.matched.reduce((acc, item) => {
    acc.push({
      name: String(item.name),
      title: ((): string => {
        if (item.meta.title) {
          return String(item.meta.title)
        }

        const translationKey = `routes.${String(item.name)}.menuTitle`
        if (te(translationKey)) {
          return t(translationKey)
        }

        return String(route.name)
      })(),
    })

    return acc
  }, [] as Breadcrumb[])
})
</script>

<style lang="scss" scoped>
.view-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  &__col {
    &--content {
      flex: 1 1 auto;
    }

    &--tools {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__title {
    margin: 0;
  }
}

.breadcrumbs {
  display: block;
  width: 100%;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item,
  &__item,
  &__separator {
    display: inline;
    padding: 0;
  }

  &__item {
    &--link {
      color: var(--color-primary);
      font-weight: var(--font-weight-bold);

      &:hover,
      &:focus-visible {
        text-decoration: underline;
        text-underline-offset: 0.4em;
      }
    }
  }

  &__separator {
    opacity: 0.5;
    margin: 0 0.5em;
  }
}
</style>
