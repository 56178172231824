<template>
  <div class="panel-close-button">
    <Button
      class="panel-close-button__button"
      :text="$t('close')"
      rounded
      size="medium"
      @click="(event: MouseEvent) => emit('click', event)"
    >
      <template #icon>
        <i-mdi-close />
      </template>
    </Button>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  click: [e: MouseEvent]
}>()
</script>

<style lang="scss" scoped>
.panel-close-button {
  z-index: 2;
  position: sticky;
  width: 100%;
  bottom: 0;

  &__button {
    position: absolute;
    bottom: var(--block-spacing-vertical-normal);
    right: var(--block-spacing-horizontal-normal);
  }
}
</style>
