import type { FavouriteStationIds, Station, StationsResponse } from '@/types'
import { ref, watch, nextTick } from 'vue'
import { watchPausable } from '@vueuse/core'
import { useApi } from '@/lib/api'
import { useI18n } from 'vue-i18n'
import { confirmModal } from '@/lib/confirm-modal'

const { request: requestStations, busy: favouriteStationsLoading } = useApi()

/**
 * Previous occured error when resolving favourite stations
 */
const favouriteStationsError = ref<string | undefined>(undefined)

/**
 * An array of favourite stations
 */
const favouriteStations = ref<Station[]>([])

/**
 * When favourite stations change, update ID's
 */
const {
  pause: pauseFavouriteStationsWatch,
  resume: resumeFavouriteStationsWatch,
} = watchPausable(
  () => favouriteStations.value,
  (val) => {
    favouriteStationIds.value = val.flatMap((station) => station.id)
  },
  {
    deep: true,
  },
)

/**
 * An array of favourite stations ID's
 */
const favouriteStationIds = ref<FavouriteStationIds>([
  ...JSON.parse(localStorage.getItem('viritinFavouriteStations') || '[]'),
])

/**
 * When favourite station ID's change, save them to localStorage
 */
watch(
  () => favouriteStationIds.value,
  (val) => {
    if (!Array.isArray(val)) {
      return
    }

    localStorage.setItem('viritinFavouriteStations', JSON.stringify(val))
  },
  {
    deep: true,
  },
)

/**
 * Composable for using favourite stations functionality
 */
export const useFavouriteStations = () => {
  const { t } = useI18n()

  const resolveFavouriteStations = async () => {
    if (!favouriteStationIds.value.length) {
      return
    }

    favouriteStationsError.value = undefined
    pauseFavouriteStationsWatch()

    try {
      const response = await (requestStations('stations/', {
        params: {
          id: favouriteStationIds.value.join('|'),
        },
      }) as Promise<StationsResponse>)

      if (response.items.length) {
        favouriteStations.value = response.items
      }
    } catch (error) {
      console.error(error)
      favouriteStationsError.value = 'favourites.resolveError'
    } finally {
      await nextTick()
      resumeFavouriteStationsWatch()
    }
  }

  const toggleFavouriteStation = (station: Station): boolean | undefined => {
    pauseFavouriteStationsWatch()

    const idx = favouriteStationIds.value.indexOf(station.id)

    // Remove
    if (idx >= 0) {
      confirmModal({
        title: t('favourites.removeConfirm', {
          stationName: station.title,
        }),
        cancelButton: t('cancel'),
        confirmButton: t('remove'),
        confirm: () => {
          favouriteStationIds.value.splice(idx, 1)
          favouriteStations.value.splice(idx, 1)
          resumeFavouriteStationsWatch()
        },
      })

      return false
    }

    // Add
    favouriteStationIds.value.push(station.id)
    favouriteStations.value.push(station)
    resumeFavouriteStationsWatch()

    return true
  }

  return {
    favouriteStationsLoading,
    favouriteStationsError,
    favouriteStations,
    favouriteStationIds,
    resolveFavouriteStations,
    toggleFavouriteStation,
  }
}
