import { globals } from './_globals'

export const fiFI = {
  ...globals,
  shortName: 'fi',
  edit: 'Muokkaa',
  finish: 'Valmis',
  country: 'Maa',
  open: 'Avaa',
  close: 'Sulje',
  copy: 'Kopioi',
  copied: 'Kopioitu',
  tryAgain: 'Yritä uudelleen',
  cancel: 'Peruuta',
  remove: 'Poista',
  clearInput: 'Poista syöte kentästä "{label}"',
  logo: {
    ariaLabel: 'Takaisin etusivulle',
  },
  breadcrumbs: {
    ariaLabel: 'Sivupolku',
  },
  updateNotification: {
    text: 'Uusi versio on saatavilla!',
    buttonText: 'Päivitä',
  },
  notifications: {
    resolveStationFromUrlError:
      'Pyytämääsi kanavaa ei löytynyt. Se saattaa olla siirretty tai poistettu.',
  },
  settings: {
    title: 'Asetukset',
  },
  theme: {
    dark: 'Tumma teema',
    light: 'Vaalea teema',
  },
  favourites: {
    add: 'Lisää suosikkeihin',
    remove: 'Poista suosikeista',
    removeConfirm: 'Poistetaanko "{stationName}" suosikeista?',
    resolveError: 'Suosikkien lataaminen ei onnistunut.',
  },
  stationList: {
    ariaLabelDefault: 'Kanavat',
  },
  tagList: {
    ariaLabelDefault: 'Tagit',
    removeHint: 'Klikkaa poistaaksesi',
  },
  actionBar: {
    ariaLabelDefault: 'Toiminnot',
  },
  share: {
    title: 'Jaa',
    texts: {
      station: 'Kuuntele radiokanavaa "{stationName}" Viritin-sovelluksessa!',
    },
    notificationTitles: {
      station: 'Seuraavaa osoitetta voidaan käyttää tämän kanavan soittamiseen',
    },
  },
  clipboard: {
    error: 'Leikepöydälle kopiointi ei onnistunut. Kopioi teksti käsin.',
  },
  searchForm: {
    title: 'Etsi kanavia',
    query: {
      label: 'Hakusana',
      placeholder: 'Kirjoita nimi, maa tai tagi',
    },
    submit: {
      text: 'Etsi',
    },
    summary:
      'Kanavia ei löytynyt. | Löydettiin yksi kanava. | Löydettiin {count} kanavaa.',
  },
  searchResults: {
    title: 'Hakutulokset',
    loadMore: 'Lataa lisää',
  },
  player: {
    buffering: 'Puskuroidaan...',
    error: 'Jokin meni pieleen!',
    controls: {
      play: 'Toista',
      pause: 'Keskeytä',
      volume: 'Äänenvoimakkuus',
    },
    nowPlaying: 'Nyt soi:',
    playlist: {
      title: 'Soittolista',
      searchFromDiscogs: 'Hae "{entry}" Discogsista',
    },
  },
  miniPlayer: {
    expand: 'Näytä laajennettu soitin',
  },
  copyright: {
    providedBy: 'Kanavatiedot tarjoaa',
    images: 'Kanavien logot ovat omistajiensa omaisuutta.',
  },
  routes: {
    favourites: {
      menuTitle: 'Suosikit',
      title: 'Suosikit',
      noItems: 'Ei suosikkeja.',
    },
    search: {
      menuTitle: 'Haku',
      title: 'Etsi kanavia',
    },
    browse: {
      menuTitle: 'Selaa',
      title: 'Selaa kanavia',
    },
    category: {
      menuTitle: 'Kategoria',
      title: 'Kategoria',
    },
    error404: {
      menuTitle: 'Sivua ei löytynyt',
      title: 'Sivua ei löytynyt',
      text: 'Etsimääsi sivua ei löytynyt. Se saattaa olla siirretty tai poistettu.',
    },
  },
}
