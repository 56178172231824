<template>
  <li
    :class="[
      'station-list-item',
      props.editable === true ? 'station-list-item--editable' : undefined,
      props.editing === true ? 'station-list-item--editing' : undefined,
    ]"
  >
    <button
      ref="buttonEl"
      type="button"
      class="station-list-item__wrap"
      :data-id="props.station.id"
      @click="emit('click', props.station)"
    >
      <div class="station-list-item__image">
        <StationImage
          size="medium"
          :image="props.station.favicon"
          :playing="config.currentStation?.id === props.station.id"
        />
      </div>
      <div class="station-list-item__content">
        <component
          class="station-list-item__title"
          :is="`h${props.headingLevel}`"
        >
          <span v-html="props.station.title" />
        </component>
        <dl
          v-if="
            (props.showTags === true && tagsString) ||
            (props.showCountry === true && props.station.country)
          "
          class="detail-list"
        >
          <div
            v-if="props.showTags === true && tagsString"
            class="detail-list__item"
          >
            <dt class="detail-list__label sr-only">
              <!-- <i-mdi-tags class="detail-list__icon" /> -->
              <span class="sr-only">
                {{ $t('tagList.ariaLabelDefault') }}:
              </span>
            </dt>
            <dd class="detail-list__value" v-html="tagsString" />
          </div>
          <div
            v-if="props.showCountry === true && props.station.country"
            class="detail-list__item"
          >
            <dt class="detail-list__label sr-only">
              <!-- <i-mdi-map-marker class="detail-list__icon" /> -->
              <span class="sr-only"> {{ $t('country') }}: </span>
            </dt>
            <dd
              class="detail-list__value"
              v-html="props.station.country.title"
            />
          </div>
        </dl>
      </div>
    </button>
    <div
      v-show="props.editable === true && props.editing === true"
      class="station-list-item__tools"
      :hidden="props.editable === false || props.editing === false"
    >
      <Button
        :text="$t('favourites.remove')"
        :tabindex="props.editing === true ? 0 : -1"
        :aria-hidden="props.editing === true ? undefined : 'true'"
        hide-text
        rounded
        @click="emit('remove', props.station)"
      >
        <template #icon>
          <i-mdi-trash-can />
        </template>
      </Button>
    </div>
  </li>
</template>

<script setup lang="ts">
import type { Station } from '@/types'
import { useTemplateRef, computed } from 'vue'
import { config } from '@/lib/config'

const props = withDefaults(
  defineProps<{
    /** Station to render */
    station: Station

    /** Heading label */
    headingLevel?: number

    /** Show tags? */
    showTags?: boolean

    /** Show country? */
    showCountry?: boolean

    /** Editable? */
    editable?: boolean

    /** Edit mode on? */
    editing?: boolean
  }>(),
  {
    headingLevel: 2,
    showTags: true,
    showCountry: true,
    editable: false,
    editing: false,
  },
)

const emit = defineEmits<{
  click: [station: Station]
  remove: [station: Station]
}>()

const buttonEl = useTemplateRef<HTMLButtonElement>('buttonEl')

const id = computed<number>(() => {
  return props.station.id
})

const tagsString = computed<string | null>(() => {
  if (!props.station.tags.length) {
    return null
  }

  return props.station.tags.flatMap((tag) => tag.title).join(', ')
})

const focus = () => {
  buttonEl.value?.focus()
}

defineExpose({
  id,
  focus,
})
</script>

<style lang="scss" scoped>
@use '@/assets/utils';

.station-list-item {
  display: block;
  position: relative;
  border-radius: 0.3em;

  /* &--sortable-ghost {
    opacity: 0;
    background: #000 !important;
  } */

  /* &--sortable-chosen {
    background: red;
  } */

  /* &__dragHandle {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-medium);
    width: 2.5rem;
    color: lighten(#000, 30);
    background: darken(#fff, 5);
    border-right: solid 1px darken(#fff, 20);
    transform: translateX(-100%);
    transition: all 200ms ease;

    &:hover {
      cursor: ns-resize;
    }

    .station-list--sorting & {
      pointer-events: auto;
      transform: translatex(0);
    }
  } */

  &__wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 2rem;
    text-decoration: none;
    width: 100%;
    text-align: left;
    color: inherit;
    border: 0;
    background-color: transparent;
    transform: translateX(0);
    transition: transform 200ms ease;
    outline-offset: 1rem;

    .station-list-item--editable & {
      padding-right: 4em;
    }

    /* .station-list--sorting & {
      pointer-events: none;
      transform: translateX(2.5rem);
    } */
  }

  &__image {
    flex: 0 0 60px;
    align-self: flex-start;
  }

  &__content {
    flex: 1 1 auto;
    align-self: center;
  }

  &__title {
    @extend .break-word;
    font-family: var(--font-sans-serif);
    font-size: var(--font-size-normal);
    line-height: var(--line-height-normal);
    font-weight: var(--font-weight-bold);
    text-underline-offset: 0.4em;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 0.6em;
    }

    .station-list-item__wrap:hover & {
      text-decoration: underline;
      text-decoration-color: var(--color-primary);
    }
  }

  &__tools {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
    transform: translateX(0);
    transition:
      opacity 300ms ease,
      transform 300ms ease,
      display 300ms ease;
    transition-behavior: allow-discrete;

    @starting-style {
      opacity: 0;
      transform: translateX(50%);
    }

    &[hidden] {
      opacity: 0;
      transform: translateX(50%);
    }
  }
}

.detail-list {
  display: block;
  margin: 0;
  font-size: var(--font-size-small);
  line-height: var(--line-height-normal);

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__label,
  &__value {
    margin: 0;
    padding: 0;
  }

  &__label {
    flex: 0 0 2em;
  }

  &__value {
    flex: 1 1 auto;
  }

  &__icon {
    opacity: 0.75;
    transform: translate(0, 0.25em);
  }
}
</style>
