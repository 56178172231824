<template>
  <ul
    ref="listEl"
    :class="['station-list']"
    tabindex="-1"
    :aria-label="props.ariaLabel || $t('stationList.ariaLabelDefault')"
  >
    <slot />
  </ul>
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

const props = withDefaults(
  defineProps<{
    /** Aria label */
    ariaLabel?: string
  }>(),
  {
    ariaLabel: undefined,
  },
)

const listEl = useTemplateRef<HTMLDivElement>('listEl')

/**
 * Focus the list element
 */
const focus = () => {
  if (!listEl.value) {
    return
  }

  listEl.value.focus({
    preventScroll: true,
  })
}

defineExpose({
  focus,
})
</script>

<style lang="scss" scoped>
.station-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: calc(var(--block-spacing-vertical-normal) * 2);
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;

  &:focus {
    outline: unset;
  }
}
</style>
