<template>
  <li class="list-item">
    <component
      :is="clickable === true ? 'button' : 'span'"
      :type="clickable === true ? 'button' : undefined"
      :class="[
        'list-item__wrap',
        props.clickable === true ? 'list-item__wrap--clickable' : undefined,
      ]"
      @click="(evt: MouseEvent) => onClick(evt)"
    >
      <div v-if="!!$slots.icon" class="list-item__icon">
        <slot name="icon" />
      </div>
      <div class="list-item__content">
        <slot name="default">
          <span v-if="props.text" class="list-item__text">
            {{ props.text }}
          </span>
          <span v-if="props.footer" class="list-item__footer">
            {{ props.footer }}
          </span>
        </slot>
      </div>
      <div v-if="!!slots.actions" class="list-item__actions">
        <slot name="actions" />
      </div>
    </component>
  </li>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    text?: string
    footer?: string
    clickable?: boolean
    align?: 'left' | 'right'
  }>(),
  {
    text: undefined,
    footer: undefined,
    clickable: true,
    align: 'left',
  },
)

const slots = defineSlots<{
  default(): any
  icon(): any
  actions(): any
}>()

const emit = defineEmits<{
  click: [evt: MouseEvent]
}>()

const onClick = (evt: MouseEvent) => {
  if (props.clickable === false) {
    return
  }

  emit('click', evt)
}
</script>

<style lang="scss" scoped>
.list-item {
  --flex-direction: row;
  --text-align: left;

  .list--align-right & {
    --flex-direction: row-reverse;
    --text-align: right;
  }

  display: block;

  &__wrap {
    display: flex;
    flex-direction: var(--flex-direction);
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    width: 100%;
    padding: 0;
    text-align: var(--text-align);
    line-height: var(----line-height-normal);
    border: 0;
    outline-offset: 1rem;
    background: transparent;
  }

  &__icon {
    font-size: 1.2em;
    color: var(--color-primary);
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0.3em;
    flex: 1 1 auto;
    text-underline-offset: 0.4em;

    .list-item__wrap--clickable:hover & {
      text-decoration: underline;
      text-decoration-color: var(--color-primary);
    }
  }

  &__text,
  &__footer {
    display: block;
  }

  &__footer {
    font-size: var(--font-size-small);
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
</style>
