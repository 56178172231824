<template>
  <InputBase
    v-bind="{ ...props, ...input }"
    :class="[
      'input-text',
      `input-text--${props.type}`,
      props.clearable === true ? `input-text--clearable` : undefined,
      !!slots.icon ? 'input-text--has-icon' : undefined,
    ]"
  >
    <span v-if="!!slots.icon" class="input-text__icon-wrap">
      <span class="input-text__icon">
        <slot name="icon" />
      </span>
    </span>
    <input
      v-model="inputValue"
      ref="inputEl"
      :id="input.id"
      :type="props.type"
      class="input-text__input"
      v-bind="$attrs"
    />
    <Button
      v-if="props.clearable === true && inputValue"
      class="input-text__clear-button"
      size="small"
      text="Clear"
      :aria-label="$t('clearInput', { label: props.label })"
      hide-text
      rounded
      @click="clear"
    >
      <template #icon>
        <i-mdi-close />
      </template>
    </Button>
  </InputBase>
</template>

<script setup lang="ts">
import { ref, useTemplateRef, watch } from 'vue'
import { watchDebounced } from '@vueuse/core'
import {
  type InputProps,
  inputPropsDefaults,
  useInput,
} from '@/composables/input'

defineOptions({
  inheritAttrs: false,
})

interface InputTextProps extends InputProps {
  type?: string
  clearable?: boolean
  debounce?: number
}

const props = withDefaults(defineProps<InputTextProps>(), {
  ...inputPropsDefaults,
  type: 'text',
  clearable: false,
  debounce: 0,
})

const slots = defineSlots<{
  icon(): any
}>()

const modelValue = defineModel<string>({
  default: '',
})

const emit = defineEmits<{
  clear: []
}>()

const input = useInput()
const inputEl = useTemplateRef<HTMLInputElement>('inputEl')

const inputValue = ref<string>('')
watchDebounced(
  () => inputValue.value,
  (val) => {
    modelValue.value = val
  },
  {
    debounce: props.debounce,
  },
)

watch(
  () => modelValue.value,
  (val) => {
    inputValue.value = val
  },
  {
    immediate: true,
  },
)

const clear = () => {
  modelValue.value = ''
  inputEl.value?.focus()

  emit('clear')
}
</script>

<style lang="scss" scoped>
.input-text {
  --padding-left: var(--input-spacing);
  --padding-right: var(--input-spacing);
  --outline-color: var(--color-border-normal);
  --icon-color: inherit;
  --icon-opacity: 0.6;

  font-size: 16px; // Just to prevent the fcking iOS Safari zooming in

  &--has-icon {
    --padding-left: calc((var(--input-spacing) * 2) + 0.75em);
  }

  &--clearable {
    --padding-right: calc(
      (var(--input-spacing) * 2) + calc(var(--font-size-small) * 2)
    );
  }

  &__input {
    display: block;
    padding: 0 var(--padding-right) 0 var(--padding-left);
    min-height: 3.2em;
    width: 100%;
    font-size: inherit;
    line-height: 1;
    color: inherit;
    background: var(--color-surface-bg);
    border: 0;
    outline: solid 1px var(--outline-color);
    border-radius: 0.3em;

    &:focus {
      --outline-color: var(--color-primary);
      --icon-color: var(--color-primary);
      --icon-opacity: 1;
    }
  }

  &__icon-wrap {
    opacity: var(--icon-opacity);
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: var(--input-spacing);
    color: var(--icon-color);
    transform: translateY(-50%);
    transition: color 200ms ease;
  }

  &__icon {
    font-size: 1.2em;
  }

  &__clear-button {
    position: absolute;
    top: 50%;
    right: var(--input-spacing);
    transform: translateY(-50%);
  }
}
</style>
