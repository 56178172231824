<template>
  <div class="updateNotification">
    <Notification type="info" :text="$t('updateNotification.text')">
      <template #actions>
        <Button
          :text="$t('updateNotification.buttonText')"
          :loading="updating"
          color="info"
          size="small"
          @click="update"
        >
          <template #icon>
            <i-mdi-cellphone-arrow-down />
          </template>
        </Button>
      </template>
    </Notification>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const emit = defineEmits<{
  update: []
}>()

const updating = ref<boolean>(false)

const update = () => {
  updating.value = true
  emit('update')
}
</script>

<style lang="scss" scoped>
.updateNotification {
  z-index: 99999;
  position: fixed;
  left: 50%;
  bottom: 5vh;
  opacity: 1;
  transform: scale(1) translateX(-50%);
  transform-origin: center;
  transition:
    opacity 300ms ease,
    transform 300ms ease,
    display 300ms ease allow-discrete;

  @starting-style {
    opacity: 0;
    transform: scale(0.7) translateX(-50%);
  }
}
</style>
