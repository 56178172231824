import { ref } from 'vue'

export interface RequestConfig {
  /** Silent mode. If true, loading will not be toggled. */
  silent?: boolean
}

export interface ApiOptions extends RequestConfig {
  /** Default value for loading state */
  loadingDefault?: boolean
}

export interface RequestOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  mode?: 'cors' | 'no-cors' | 'same-origin'
  cache?:
    | 'default'
    | 'no-store'
    | 'reload'
    | 'no-cache'
    | 'force-cache'
    | 'only-if-cached'
  headers?: Record<string, string>
  body?: BodyInit
  params?: Record<string, any>
  signal?: AbortSignal
}

export const useApi = (userOptions: ApiOptions = {}) => {
  const apiOptions: ApiOptions = {
    silent: false,
    loadingDefault: false,
    ...userOptions,
  }

  const loading = ref<boolean>(apiOptions.loadingDefault === true)
  const busy = ref<boolean>(false)
  const error = ref<string | null>(null)
  const abortController = ref<AbortController | undefined>(undefined)

  return {
    loading,
    busy,
    error,
    request: (
      url: string,
      options: RequestOptions = {},
      config: RequestConfig = {},
    ) => {
      // Cancel the previous request
      // TODO: for some reason after aborting, the next request has loading === false
      if (abortController.value) {
        abortController.value.abort()
      }

      abortController.value = new AbortController()

      config = {
        silent: apiOptions.silent,
        ...config,
      }

      options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        ...options,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...options.headers,
        },
        signal: abortController.value.signal,
      }

      error.value = null
      busy.value = true

      if (config.silent === false) {
        loading.value = true
      }

      // Get params, removing empty values
      const params = Object.entries(options.params || {}).reduce(
        (acc, [key, value]) => {
          if (!value) {
            return acc
          }

          acc.set(key, value)
          return acc
        },
        new URLSearchParams(),
      )

      // This is goofy, but on iOS 17 params.size is undefined :D
      const paramsStr = ((): string => {
        const out = params.toString()

        return out.length ? `?${out}` : ''
      })()

      const requestUrl = `${import.meta.env.VITE_API_ROOT}/${url}${paramsStr}`

      return fetch(requestUrl, options)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`)
          }

          const json = await response.json()

          if (json.time || json.selector) {
            console.table({
              time: json.time || null,
              selector: json.selector || null,
            })
          }

          return json
        })
        .catch((error) => {
          // Eat AbortErrors
          if (error.name == 'AbortError') {
            return
          }

          throw error
        })
        .finally(() => {
          loading.value = false
          busy.value = false
        })
    },
  }
}
