<template>
  <span
    :class="[
      'spinner',
      `spinner--size-${props.size}`,
      `spinner--color-${props.color}`,
      props.overlay ? 'spinner--overlay' : null,
    ]"
  >
    <span class="spinner__animation" />
  </span>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    size?: 'small' | 'normal' | 'medium' | 'large'
    color?: 'primary' | 'info'
    overlay?: boolean
  }>(),
  {
    size: 'normal',
    color: undefined,
    overlay: false,
  },
)
</script>

<style lang="scss" scoped>
@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}

.spinner {
  --font-size: inherit;
  --color: currentColor;

  display: block;
  width: 1em;
  font-size: var(--font-size);
  color: var(--color);
  margin: auto;

  &--color {
    &-primary {
      --color: var(--color-primary);
    }

    &-info {
      --color: var(--color-info);
    }
  }

  &--size {
    &-small {
      --font-size: 0.85em;
    }

    &-normal {
      --font-size: 1em;
    }

    &-medium {
      --font-size: 1.5em;
    }

    &-large {
      --font-size: 2em;
    }
  }

  &--overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__animation {
    display: block;
    width: 1em;
    height: 1em;
    border: clamp(2px, 0.1em, 10em) solid currentColor;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 1em;
      height: 1em;
      border: clamp(2px, 0.1em, 10em) solid currentColor;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      animation: scaleUp 1s linear infinite;
    }
  }
}
</style>
