<template>
  <button
    ref="el"
    :type="props.type"
    :class="[
      'button',
      `button--size-${props.size}`,
      `button--color-${props.color}`,
      props.disabled === true || props.loading === true
        ? 'button--disabled'
        : undefined,
      props.square === true ? 'button--square' : undefined,
      props.rounded === true ? 'button--rounded' : undefined,
      props.loading === true ? 'button--loading' : undefined,
    ]"
    :aria-disabled="
      props.disabled === true || props.loading === true ? 'true' : undefined
    "
    :aria-label="textIsHidden === true ? props.text : undefined"
    @click="(evt) => onClick(evt)"
  >
    <Spinner
      v-if="loading === true"
      class="button__spinner"
      :size="props.size"
      :color="props.color"
      overlay
    />
    <div class="button__content">
      <span v-if="!!slots.icon" class="button__icon">
        <slot name="icon" />
      </span>
      <span
        v-if="props.text && textIsHidden === false"
        class="button__text"
        v-html="props.text"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

const props = withDefaults(
  defineProps<{
    type?: 'button' | 'submit' | 'reset'
    text: string
    color?: 'primary' | 'info' | 'error'
    size?: 'small' | 'normal' | 'medium'
    hideText?: boolean
    disabled?: boolean
    square?: boolean
    rounded?: boolean
    loading?: boolean
  }>(),
  {
    type: 'button',
    color: 'primary',
    size: 'normal',
    hideText: false,
    disabled: false,
    square: false,
    rounded: false,
    loading: false,
  },
)

const emit = defineEmits<{
  click: [evt: MouseEvent]
}>()

const slots = defineSlots<{
  icon(): any
}>()

const textIsHidden = computed<boolean>(() => {
  return props.hideText === true || props.rounded === true
})
const el = useTemplateRef<HTMLButtonElement>('el')

const onClick = (evt: MouseEvent) => {
  if (props.disabled === true || props.loading === true) {
    evt.preventDefault()
    evt.stopPropagation()
    return false
  }

  emit('click', evt)
}
</script>

<style lang="scss">
.button {
  --cursor: pointer;
  --font-size: var(--font-size-normal);
  --font-size-factor: 1;
  --color: inherit;
  --background-color: var(--color-surface-bg);
  --border-color: var(--color-border-normal);
  --border-radius: 0.3em;
  --color-highlight: transparent;
  --min-height: unset;
  --opacity: 1;
  --content-opacity: 1;
  --content-padding: 0.5em 1em;
  --content-size: unset;

  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  opacity: var(--opacity);
  cursor: var(--cursor);
  min-height: var(--min-height);
  font-size: var(--font-size);
  line-height: var(--line-height-normal);
  text-align: left;
  white-space: nowrap;
  outline: 0;
  color: var(--color);
  background-color: var(--background-color);
  border: solid 1px var(--border-color);
  border-radius: var(--border-radius);
  transition:
    border-color 200ms ease,
    color 200ms ease,
    background-color 200ms ease;

  &--disabled {
    --opacity: 0.6;
    --cursor: not-allowed;
  }

  &--loading {
    --content-opacity: 0;
  }

  &--size {
    &-small {
      --font-size: calc(var(--font-size-small) * var(--font-size-factor));
    }

    &-normal {
      --font-size: calc(var(--font-size-normal) * var(--font-size-factor));
    }

    &-medium {
      --font-size: calc(var(--font-size-medium) * var(--font-size-factor));
    }

    &-large {
      --font-size: calc(var(--font-size-large) * var(--font-size-factor));
    }
  }

  &--color {
    &-primary {
      --color-highlight: var(--color-primary);
    }

    &-info {
      --color-highlight: var(--color-info);
    }

    &-error {
      --color-highlight: var(--color-error);
    }
  }

  &--square {
    --font-size-factor: 1.2;
    --content-size: 1.8em;
    --content-padding: 0;
  }

  &--rounded {
    --font-size-factor: 1.2;
    --content-size: 1.8em;
    --content-padding: 0;
    --border-radius: 10em;
  }

  &:not([aria-disabled]) {
    &:hover,
    &:focus-visible {
      --color: #fff;
      --background-color: var(--color-highlight);
      --border-color: var(--color-highlight);
    }
  }

  &__content {
    opacity: var(--content-opacity);
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.75em;
    padding: var(--content-padding);
    width: var(--content-size);
    height: var(--content-size);
  }

  &__icon,
  &__text {
    display: block;
  }

  &__icon {
    font-size: inherit;
  }

  &__text {
    flex: 1 1 auto;
  }
}
</style>
