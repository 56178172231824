<template>
  <div
    :class="[
      'notification',
      `notification--size-${props.size}`,
      `notification--type-${props.type}`,
    ]"
  >
    <div class="notification__wrap">
      <div class="notification__content">
        <h2 v-if="props.title" class="notification__title">
          {{ props.title }}
        </h2>
        <p v-if="props.text" class="notification__text">
          {{ props.text }}
        </p>
      </div>
      <template v-if="!!slots.actions">
        <div class="notification__actions">
          <slot name="actions" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type?: 'info' | 'error'
    title?: string
    text?: string
    size?: 'small' | 'normal'
  }>(),
  {
    type: 'info',
    title: undefined,
    text: undefined,
    size: 'normal',
  },
)

const slots = defineSlots<{
  actions(): any
}>()
</script>

<style lang="scss" scoped>
@use '@/assets/utils';

.notification {
  --font-size: unset;
  --padding: 0;
  --border-color: transparent;
  --border-width: 0;

  font-size: var(--font-size-smaller);

  &:not(:first-child) {
    margin-top: 1em;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  &--type {
    &-info {
      --border-color: var(--color-info);
    }

    &-error {
      --border-color: var(--color-error);
    }
  }

  &--size {
    &-small {
      --padding: 0.2em 1em;
      --font-size: var(--font-size-small);
      --border-width: 0.5em;
    }

    &-normal {
      --padding: 0.75em 1.5em;
      --border-width: 0.75em;
    }
  }

  &__wrap {
    @include mixins.shadow();
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5em;
    padding: var(--padding);
    font-size: var(--font-size);
    text-align: left;
    border-left: solid var(--border-width) var(--border-color);
    border-radius: 0.3em;
    background: var(--color-surface-bg);
  }

  &__content {
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75em;
  }

  &__title,
  &__text {
    @extend .break-word;
  }

  &__title {
    font-family: var(--font-sans-serif);
    font-weight: var(--font-weight-bold);
    font-size: inherit;
  }

  &__text {
    font-size: inherit;
    margin: 0;
  }
}
</style>
