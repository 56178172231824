<template>
  <router-link
    :to="{ name: 'search' }"
    class="logo"
    :aria-label="$t('logo.ariaLabel')"
  >
    Viritin
  </router-link>
</template>

<style lang="scss" scoped>
.logo {
  display: block;
  z-index: 100;
  position: absolute;
  top: var(--block-spacing-horizontal-normal);
  left: var(--block-spacing-horizontal-normal);
  padding: 1em 1.5em;
  font-family: var(--font-serif);
  font-size: 1.2em;
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  color: #fff;
  outline: 0;
  background: var(--color-primary);

  @media (min-width: config.$bp-desktop) {
    top: 0;
    left: calc(var(--header-size) + var(--block-spacing-horizontal-normal));
    padding-top: 1.5em;
  }

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    text-underline-offset: 0.4em;
  }
}
</style>
