<template>
  <ViewHeader />
  <Block>
    <Form
      class="search-form"
      role="search"
      :aria-label="$t('searchForm.title')"
      @submit.prevent="onSubmit"
    >
      <InputText
        class="test"
        v-model="formData.q"
        :label="$t('searchForm.query.label')"
        hide-label
        clearable
        :placeholder="$t('searchForm.query.placeholder')"
        :debounce="300"
      >
        <template #icon>
          <i-mdi-search />
        </template>
      </InputText>
      <FormContent v-if="resolvedTags.length">
        <TagList
          v-model="resolvedTags"
          removable
          @item-remove="(_item: Tag) => removeTag()"
        />
      </FormContent>
      <FormContent class="sr-only">
        <Button
          type="submit"
          :text="$t('searchForm.submit.text')"
          tabindex="-1"
        />
      </FormContent>
    </Form>
  </Block>
  <SearchResults
    ref="searchResults"
    :q="formData.q"
    :tag="formData.tag"
    :category="formData.category"
  />
</template>

<script setup lang="ts">
import type { Tag } from '@/types'
import { ref, useTemplateRef, onActivated, onDeactivated } from 'vue'
import { watchPausable } from '@vueuse/core'
import { useRouteQuery } from '@vueuse/router'
import { useApi } from '@/lib/api'
import SearchResults from '@/components/SearchResults.vue'

interface SearchTerms {
  q: string
  tag: string
  category: string
}

const searchResultsComponent =
  useTemplateRef<InstanceType<typeof SearchResults>>('searchResults')
const tagsApi = useApi()
const resolvedTags = ref<Tag[]>([])
const formData = ref<SearchTerms>({ q: '', tag: '', category: '' })

const onSubmit = () => {
  if (!formData.value.q) {
    return
  }

  searchResultsComponent.value?.focus()
}

const queryParams = ref({
  tag: useRouteQuery('tag'),
})

const checkTag = async (tag: string | string[] | null = null) => {
  if (!tag) {
    return
  }

  tag = String(tag)
  if (!tag) {
    return
  }

  // Resolve requested tag
  try {
    const response = await (tagsApi.request(`tags/${tag}/`) as Promise<Tag>)
    resolvedTags.value = [response]
  } catch (_e) {
    resolvedTags.value = []
  }

  if (!resolvedTags.value.length) {
    return
  }

  // Tag was found. Accept it as a search term.
  formData.value = {
    ...formData.value,
    q: '',
    tag: tag,
  }
}

const { pause: pauseTagWatch, resume: resumeTagWatch } = watchPausable(
  () => queryParams.value.tag,
  (tag) => {
    checkTag(tag)
  },
)

const removeTag = () => {
  formData.value.tag = ''
  resolvedTags.value = []
}

onActivated(() => {
  checkTag(queryParams.value.tag)

  resumeTagWatch()
})

onDeactivated(() => {
  pauseTagWatch()
})
</script>

<style lang="scss" scoped>
.search-form {
  width: 100%;
  max-width: 500px;
}
</style>
