import type { Station } from '@/types'

export const setMediaSessionMetadata = (
  station?: Station,
  title?: string,
  artist?: string,
): MediaMetadata | undefined => {
  if (!('mediaSession' in navigator)) {
    return undefined
  }

  navigator.mediaSession.metadata = new MediaMetadata({
    title: title || station?.title,
    artist: artist,
    album: title && artist ? station?.title : undefined,
    artwork: station?.favicon
      ? [
          {
            src: station.favicon.url,
            sizes: `${station.favicon.width}x${station.favicon.height}`,
            type: 'image/webp',
          },
        ]
      : undefined,
  })

  return navigator.mediaSession.metadata
}

export const setMediaSessionActionHandler = (
  action: MediaSessionAction,
  handler: MediaSessionActionHandler | null,
): void => {
  if (!('mediaSession' in navigator)) {
    return
  }

  navigator.mediaSession.setActionHandler(action, handler)
}

export const setMediaSessionPlaybackState = (
  state: MediaSessionPlaybackState,
): MediaSessionPlaybackState | undefined => {
  if (!('mediaSession' in navigator)) {
    return undefined
  }

  navigator.mediaSession.playbackState = state
  return navigator.mediaSession.playbackState
}
