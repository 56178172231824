<template>
  <ul
    v-if="items.length"
    :class="[
      'tag-list',
      `tag-list--size-${props.size}`,
      `tag-list--justify-${props.justify}`,
    ]"
    :aria-label="props.ariaLabel || $t('tagList.ariaLabelDefault')"
  >
    <li v-for="item of items" :key="item.id" class="tagList__item">
      <component
        :is="wrapIsButton === true ? 'button' : 'span'"
        :type="wrapIsButton === true ? 'button' : undefined"
        :class="['tag', wrapIsButton === true ? 'tag--clickable' : undefined]"
        @click="onItemClick(item)"
      >
        <span class="tag__text" v-html="item.title" />
        <template v-if="props.removable === true">
          <span class="sr-only"> ({{ $t('tagList.removeHint') }}) </span>
          <i-mdi-close class="tag__remove-icon" />
        </template>
      </component>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { Tag } from '@/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    /** Size */
    size?: 'small' | 'normal'

    /** Item justification */
    justify?: 'start' | 'center'

    /** Aria label */
    ariaLabel?: string

    /** Clickable */
    clickable?: boolean

    /** Removable */
    removable?: boolean
  }>(),
  {
    size: 'normal',
    justify: 'start',
    ariaLabel: undefined,
    clickable: false,
    removable: false,
  },
)

const emit = defineEmits<{
  'item-click': [item: Tag]
  'item-remove': [item: Tag]
}>()

const items = defineModel<Tag[]>({
  default: () => [],
})

const wrapIsButton = computed<boolean>(() => {
  return props.clickable === true || props.removable === true
})

const onItemClick = (item: Tag) => {
  if (props.removable === true) {
    emit('item-remove', item)
    return
  }

  if (props.clickable === true) {
    emit('item-click', item)
  }
}
</script>

<style lang="scss" scoped>
.tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  gap: 0.75em;
  line-height: 1.2;
  margin: 0;
  padding: 0;

  &--size {
    &-small {
      font-size: var(--font-size-small);
    }

    &-normal {
      font-size: var(--font-size-smaller);
    }
  }

  &--justify {
    &-start {
      justify-content: flex-start;
    }

    &-center {
      justify-content: center;
    }
  }

  &__item {
    display: inline-flex;
  }
}

.tag {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.4em;
  align-items: center;
  padding: 0.3em 1em;
  border-radius: 10em;
  color: inherit;
  outline: 0;
  border: solid 1px var(--color-border-normal);
  background: transparent;
  transition:
    border-color 200ms ease,
    color 200ms ease,
    background-color 200ms ease;

  &--clickable {
    &:hover,
    &:focus {
      color: #fff;
      background: var(--color-primary);
      border-color: var(--color-primary);
    }
  }
}
</style>
