import { useBartender } from '@fokke-/vue-bartender.js'

interface ConfirmModalOptions {
  title?: string
  cancelButton?: string
  confirmButton?: string
  cancel?: () => void
  confirm?: () => void
}

let counter = 0

const confirmModalDefaultOptions: ConfirmModalOptions = {
  title: 'Confirm',
  cancelButton: 'Cancel',
  confirmButton: 'Confirm',
  cancel: () => {},
  confirm: () => {},
}

export const confirmModal = (options: ConfirmModalOptions = {}) => {
  const barName = `confirmModal${counter++}`

  const cancel = () => {
    bartender?.close(barName)

    if (typeof options.cancel === 'function') {
      options.cancel()
    }
  }

  const confirm = () => {
    bartender?.close(barName)

    if (typeof options.confirm === 'function') {
      options.confirm()
    }
  }

  options = {
    ...confirmModalDefaultOptions,
    ...Object.entries(options).reduce(
      (acc, [key, value]) => {
        if (typeof value === 'undefined') {
          return acc
        }

        acc[key] = value
        return acc
      },
      {} as Record<string, any>,
    ),
  }

  const el = document.createElement('dialog')
  el.classList.add('confirm-modal')
  document.body.appendChild(el)

  const content = document.createElement('div')
  content.classList.add('confirm-modal__content')
  el.appendChild(content)

  const title = document.createElement('h2')
  title.classList.add('confirm-modal__title')
  title.textContent = options.title || null
  content.appendChild(title)

  const buttonList = document.createElement('div')
  buttonList.classList.add('confirm-modal__button-list')
  content.appendChild(buttonList)

  const cancelButton = document.createElement('button')
  cancelButton.setAttribute('type', 'button')
  cancelButton.classList.add(
    'button',
    'button--size-normal',
    'button--color-primary',
  )
  cancelButton.addEventListener('click', () => {
    cancel()
  })
  buttonList.appendChild(cancelButton)

  const cancelButtonContent = document.createElement('div')
  cancelButtonContent.classList.add('button__content')
  cancelButtonContent.textContent = options.cancelButton || ''
  cancelButton.appendChild(cancelButtonContent)

  const confirmButton = document.createElement('button')
  confirmButton.setAttribute('type', 'button')
  confirmButton.classList.add(
    'button',
    'button--size-normal',
    'button--color-primary',
  )
  confirmButton.addEventListener('click', () => {
    confirm()
  })
  buttonList.appendChild(confirmButton)

  const confirmButtonContent = document.createElement('div')
  confirmButtonContent.classList.add('button__content')
  confirmButtonContent.textContent = options.confirmButton || ''
  confirmButton.appendChild(confirmButtonContent)

  const bartender = useBartender()
  const barInstance = bartender?.addBar(barName, {
    el: el,
    position: 'center',
  })

  barInstance?.el.addEventListener('bartender-bar-after-close', () => {
    bartender?.removeBar(barName)
    document.body.removeChild(el)
  })

  barInstance?.el.addEventListener('bartender-bar-backdrop-click', () => {
    cancel()
  })

  bartender?.open(barName, true)

  return true
}
