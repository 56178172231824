<template>
  <li :class="['action-bar-item']">
    <button
      :class="['action-bar-item__wrap']"
      @click="(evt: MouseEvent) => emit('click', evt)"
    >
      <span v-if="!!slots.icon" class="action-bar-item__icon">
        <slot name="icon" />
      </span>
      <span class="action-bar-item__text">
        {{ props.text }}
      </span>
    </button>
  </li>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    text: string
  }>(),
  {},
)

const slots = defineSlots<{
  icon(): any
}>()

const emit = defineEmits<{
  click: [evt: MouseEvent]
}>()
</script>

<style lang="scss" scoped>
.action-bar-item {
  --font-size: var(--font-size-normal);
  --text-decoration: none;

  display: block;
  font-size: var(--font-size);

  &__wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.75rem;
    padding: 1.2em 1em;
    border: 0;

    &:hover,
    &:focus {
      --text-decoration: underline;
    }
  }

  &__icon {
    font-size: 1.2em;
    color: var(--color-primary);
  }

  &__text {
    display: block;
    line-height: var(--line-height-normal);
    text-decoration: var(--text-decoration);
    text-decoration-color: var(--color-primary);
    text-underline-offset: 0.4em;
  }
}
</style>
