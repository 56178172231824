<template>
  <Block class="header__nav-container" :spacing-x="null" align="right">
    <MainNav class="header__nav" />
  </Block>
  <Block :spacing-x="'small'" bordered align="right">
    <List align="right">
      <ListItem
        :text="$t('settings.title')"
        @click="bartender?.open('settings')"
      >
        <template #icon>
          <i-mdi-cog />
        </template>
      </ListItem>
    </List>
  </Block>
</template>

<script setup lang="ts">
import { useBartender } from '@fokke-/vue-bartender.js'

const bartender = useBartender()
</script>
