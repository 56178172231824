<template>
  <component
    ref="el"
    :is="tag"
    :class="[
      'main-col',
      `main-col--type-${props.type}`,
      props.hideMobile === true ? 'main-col--hide-mobile' : undefined,
      props.hideDesktop === true ? 'main-col--hide-desktop' : undefined,
      props.sticky ? `main-col--sticky-${props.sticky}` : undefined,
      props.padded === true ? `main-col--padded` : undefined,
      contentFits === true ? 'main-col--content-fits' : undefined,
    ]"
    tabindex="-1"
  >
    <div
      :class="[
        'main-col__content',
        contentFits === true ? 'main-col__content--content-fits' : undefined,
      ]"
    >
      <slot name="default" />
    </div>
  </component>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type: 'header' | 'content' | 'footer'
    hideMobile?: boolean
    hideDesktop?: boolean
    sticky?: 'top' | 'bottom'
    padded?: boolean
  }>(),
  {
    hideMobile: false,
    hideDesktop: false,
    sticky: undefined,
    padded: false,
  },
)

defineSlots<{
  default(): any
}>()

import { type Ref, ref, useTemplateRef, computed, provide } from 'vue'
import { useWindowSize } from '@vueuse/core'
import {
  registerBlockInjectionKey,
  deregisterBlockInjectionKey,
} from '@/lib/injection-keys'

const tag = computed<string>(() => {
  if (props.type === 'header') {
    return 'header'
  }

  if (props.type === 'content') {
    return 'main'
  }

  if (props.type === 'footer') {
    return 'footer'
  }

  return 'div'
})

const el = useTemplateRef<HTMLElement>('el')
const { height: windowHeight } = useWindowSize()

const blocks = ref<Record<number, Ref<number>>>({})
const contentHeight = computed<number>(() => {
  return Object.values(
    blocks.value as unknown as Record<number, number>,
  ).reduce((acc, height) => (acc += height), 0)
})
const contentFits = computed<boolean>(() => {
  return windowHeight.value >= contentHeight.value
})

const registerBlock = (uid: number, height: Ref<number>) => {
  blocks.value[uid] = height
}
provide(registerBlockInjectionKey, registerBlock)

const deregisterBlock = (uid: number) => {
  if (!blocks.value[uid]) {
    return
  }

  delete blocks.value[uid]
}
provide(deregisterBlockInjectionKey, deregisterBlock)
</script>

<style lang="scss" scoped>
.main-col {
  --display: flex;
  --position: relative;
  --top: unset;
  --bottom: unset;
  --content-position: relative;
  --content-top: unset;

  display: var(--display);
  flex-direction: column;
  flex-wrap: nowrap;
  position: var(--position);
  top: var(--top);
  bottom: var(--bottom);
  outline: 0;

  &--content-fits {
    --content-position: sticky;
    --content-top: 0;
  }

  &--hide-mobile {
    --display: none;

    @media (min-width: config.$bp-desktop) {
      --display: block;
    }
  }

  &--hide-desktop {
    --display: block;

    @media (min-width: config.$bp-desktop) {
      --display: none;
    }
  }

  &--sticky {
    &-top,
    &-bottom {
      --position: sticky;
    }

    &-top {
      --top: 0;
    }

    &-bottom {
      --bottom: 0;
    }
  }

  &--type {
    &-header {
      flex: 0 0 var(--header-size);
      border-right: solid 1px var(--color-border-light);

      .main-col__content--content-fits {
        justify-content: space-between;
        height: 100vh;
        height: 100dvh;
      }
    }

    &-content {
      flex: 1 1 auto;

      @media (min-width: config.$bp-desktop) {
        margin-right: var(--player-size);
      }
    }

    &-footer {
      @include mixins.shadow('up');
      background: var(--color-surface-bg);
    }
  }

  &__content {
    position: var(--content-position);
    top: var(--content-top);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
</style>
