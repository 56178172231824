<template>
  <ul
    :class="[
      'list',
      `list--align-${props.align}`,
      props.fullWidth === true ? 'list--full-width' : undefined,
    ]"
  >
    <slot />
  </ul>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    /** Item alignment */
    align?: 'left' | 'right'

    /** Full width */
    fullWidth?: boolean
  }>(),
  {
    align: 'left',
    fullWidth: false,
  },
)

defineSlots<{
  default(): any
}>()
</script>

<style lang="scss" scoped>
.list {
  --max-width: 500px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1.5em;
  list-style: none;
  width: 100%;
  max-width: var(--max-width);
  padding: 0;

  &--full-width {
    width: 100%;
  }
}
</style>
