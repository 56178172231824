<template>
  <ul
    :class="['action-bar']"
    :aria-label="props.ariaLabel || $t('actionBar.ariaLabelDefault')"
  >
    <slot name="default" />
  </ul>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    /** Aria label */
    ariaLabel?: string
  }>(),
  {
    ariaLabel: undefined,
  },
)

defineSlots<{
  default(): any
}>()
</script>

<style lang="scss" scoped>
.action-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}
</style>
