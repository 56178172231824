<template>
  <ViewHeader>
    <template #tools>
      <Button
        ref="editButton"
        type="button"
        :text="editing === true ? $t('finish') : $t('edit')"
        hide-text
        rounded
        :disabled="!favouriteStations.length"
        @click="() => (editing = !editing)"
      >
        <template #icon>
          <i-mdi-check v-if="editing === true" />
          <i-mdi-pencil v-else />
        </template>
      </Button>
    </template>
  </ViewHeader>

  <Block>
    <Spinner
      v-if="favouriteStationsLoading === true"
      size="large"
      color="primary"
    />
    <Notification
      v-else-if="favouriteStationsError"
      :text="$t(favouriteStationsError)"
      type="error"
    >
      <template #actions>
        <Button
          :text="$t('tryAgain')"
          @click="resolveFavouriteStations"
          size="small"
          color="error"
        >
          <template #icon>
            <i-mdi-refresh />
          </template>
        </Button>
      </template>
    </Notification>
    <template v-else>
      <Notification
        v-if="!favouriteStations.length"
        :text="$t('routes.favourites.noItems')"
      >
        <template #actions>
          <Button
            :text="$t('routes.search.menuTitle')"
            color="info"
            size="small"
            @click="router.push({ name: 'search' })"
          >
            <template #icon>
              <i-mdi-search />
            </template>
          </Button>
          <!-- TODO: return this -->
          <!-- <Button
            :text="$t('routes.browse.menuTitle')"
            color="info"
            size="small"
            @click="router.push({ name: 'browse' })"
          >
            <template #icon>
              <i-mdi-book-open-page-variant />
            </template>
          </Button> -->
        </template>
      </Notification>
      <StationList v-else ref="stationList">
        <StationListItem
          v-for="item of favouriteStations"
          :key="item.id"
          ref="stationListItemComponents"
          :station="item"
          :show-tags="true"
          :show-country="false"
          editable
          :editing="editing"
          @click="(station: Station) => setStation(station)"
          @remove="(station: Station) => toggleFavouriteStation(station)"
        />
      </StationList>
    </template>
  </Block>
</template>

<script setup lang="ts">
import type { Station } from '@/types'
import {
  ref,
  // useTemplateRef
} from 'vue'
import { useRouter } from 'vue-router'
import { useFavouriteStations } from '@/lib/favouriteStations'
import { setStation } from '@/lib/player'
import Button from '@/components/Button.vue'

const router = useRouter()
const {
  resolveFavouriteStations,
  favouriteStations,
  favouriteStationsLoading,
  favouriteStationsError,
  toggleFavouriteStation,
} = useFavouriteStations()

// const editButtonComponent =
//   useTemplateRef<InstanceType<typeof Button>>('editButton')

// const onClickOutsideHandler = [
//   (_event: MouseEvent) => {
//     editing.value = false
//   },
//   {
//     ignore: [editButtonComponent],
//   },
// ]

const editing = ref<boolean>(false)
</script>
