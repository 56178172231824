export const copy = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    console.error(error)
    return false
  }

  return true
}
