<template>
  <!-- <div class="background-image">
    <img
      class="background-image__image"
      src="https://api.viritin.fi.ddev.site/site/assets/files/66940/miroslav-matthess-ennclyvi1v8-unsplash.webp"
      loading="lazy"
    />
  </div> -->
  <Logo />

  <div class="main-col-container">
    <MainCol type="header" padded hide-mobile>
      <Header />
    </MainCol>
    <MainCol ref="contentCol" id="content" type="content" padded>
      <router-view v-slot="{ Component }">
        <!-- TODO: try to inject this to the view -->
        <Block v-if="notification">
          <Notification type="error" :text="$t(notification)">
            <template #actions>
              <Button
                text="OK"
                size="small"
                color="error"
                @click="notification = ''"
              >
                <template #icon>
                  <i-mdi-check />
                </template>
              </Button>
            </template>
          </Notification>
        </Block>

        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </MainCol>
    <MainCol type="footer" sticky="bottom" hide-desktop>
      <Block v-show="config.currentStation" :spacing-y="null" bordered>
        <MiniPlayer ref="miniPlayer" />
      </Block>
      <Block :spacing-y="null" bordered align="center">
        <MainNav horizontal render-settings />
      </Block>
    </MainCol>
  </div>

  <MobilePlayerBar />
  <DesktopPlayerBar ref="desktopPlayerBarComponent" />
  <SettingsBar />

  <UpdateNotification
    v-if="updateAvailable === true"
    @update="updateSW(true)"
  />
</template>

<script setup lang="ts">
import type { StationsResponse } from '@/types'
import { ref, useTemplateRef, onMounted, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { registerSW } from 'virtual:pwa-register'
import { useI18n } from 'vue-i18n'
import { config } from '@/lib/config'
import { useApi } from '@/lib/api'
import { useFavouriteStations } from '@/lib/favouriteStations'
import { isPlaying, setStation } from '@/lib/player'
import { isSupported } from '@/lib/isSupported'
import { useRouteQuery } from '@vueuse/router'
import { useBartender } from '@fokke-/vue-bartender.js'

import MainCol from '@/components/MainCol.vue'
import MiniPlayer from '@/components/MiniPlayer.vue'
import DesktopPlayerBar from '@/components/DesktopPlayerBar.vue'

const route = useRoute()
const router = useRouter()
const { t, locale } = useI18n()
const bartender = useBartender()
const { resolveFavouriteStations, favouriteStationIds } = useFavouriteStations()
const notification = ref<string>('')

const contentColComponent =
  useTemplateRef<InstanceType<typeof MainCol>>('contentCol')
const desktopPlayerBarComponent = useTemplateRef<
  InstanceType<typeof DesktopPlayerBar>
>('desktopPlayerBarComponent')
const miniPlayerComponent =
  useTemplateRef<InstanceType<typeof MiniPlayer>>('miniPlayer')

onMounted(() => {
  resolveFavouriteStations()

  if (config.value.currentStation) {
    setStation(config.value.currentStation, false)
    bartender?.open('desktop-player', true)
  }
})

/**
 * When service worked has been updated, show update notification
 */
const updateAvailable = ref<boolean>(false)
const updateSW = registerSW({
  onNeedRefresh() {
    updateAvailable.value = true
  },
  onOfflineReady() {},
})

/**
 * Document title updater
 */
watch(
  () => [
    route.name,
    locale.value,
    config.value.currentStation,
    isPlaying.value,
  ],
  () => {
    const siteName = 'Viritin.fi'
    const pageTitle = ((): string | undefined => {
      if (config.value.currentStation && isPlaying.value === true) {
        return config.value.currentStation.title
      }

      if (route.meta.title) {
        return String(route.meta.title)
      }

      if (route.name) {
        return t(`routes.${String(route.name)}.title`)
      }

      return undefined
    })()

    if (pageTitle) {
      document.title = `${pageTitle} - ${siteName}`
      return
    }

    document.title = siteName
  },
  {
    immediate: true,
  },
)

/**
 * Set lang attribute
 */
watch(
  () => locale.value,
  () => {
    document.documentElement.setAttribute('lang', t('shortName'))
  },
  {
    immediate: true,
  },
)

watch(
  () => route.name,
  (val, previousVal) => {
    // If the first route the user visits is favourites view,
    // and favourites is empty, redirect to search
    if (
      !previousVal &&
      val === 'favourites' &&
      !favouriteStationIds.value.length
    ) {
      router.replace({ name: 'search' })
    }

    // Focus to content
    contentColComponent.value?.$el.focus()
  },
)

/**
 * When current station changes, focus on player
 */
watch(
  () => config.value.currentStation,
  async (val) => {
    if (val) {
      bartender?.open('desktop-player', true)
    } else {
      bartender?.close('desktop-player')
    }

    await nextTick()

    if (val && isSupported.checkVisibility === true) {
      if (miniPlayerComponent.value?.$el.checkVisibility() === true) {
        miniPlayerComponent.value.$el.focus({ preventScroll: true })
      } else if (
        desktopPlayerBarComponent.value?.$el.checkVisibility() === true
      ) {
        desktopPlayerBarComponent.value.$el.focus({ preventScroll: true })
      }
    }
  },
)

/**
 * When station is requested by URL, try to resolve it
 */
const playRouteQuery = useRouteQuery('play')
const stationsApi = useApi()

watch(
  () => playRouteQuery.value,
  async (val) => {
    if (!val) {
      return
    }

    try {
      const response = await (stationsApi.request(`stations/`, {
        params: {
          id: val,
        },
      }) as Promise<StationsResponse | undefined>)

      if (!response || !response?.items.length) {
        notification.value = 'notifications.resolveStationFromUrlError'
        return
      }

      setStation(response.items[0], false)
      await nextTick()

      if (miniPlayerComponent.value?.$el.checkVisibility() === true) {
        miniPlayerComponent.value.expand()
      }
    } catch (_e) {
      notification.value = 'notifications.resolveStationFromUrlError'
    } finally {
      playRouteQuery.value = null
    }
  },
  {
    immediate: true,
  },
)
</script>

<style lang="scss">
@use '@/assets/utils';

@import '@fokke-/bartender.js/dist/bartender.css';
@import '@fontsource/playfair-display/latin-400.css';
@import '@fontsource/playfair-display/latin-600.css';
@import '@fontsource/inter/latin-400.css';
@import '@fontsource/inter/latin-600.css';
@import 'modern-normalize/modern-normalize.css';

:root {
  /* Colors */
  --color-primary: hsl(20, 85%, 42%);
  --color-primary-transparent: hsla(20, 85%, 42%, 0.85);
  --color-body-text: hsl(180, 4%, 21%);
  --color-body-bg: hsl(30, 20%, 98%);
  --color-surface-bg: hsl(30, 20%, 99%);
  --color-border-light: hsl(180, 4%, 90%);
  --color-border-normal: hsl(180, 4%, 75%);
  --color-shadow: rgba(0, 0, 0, 0.03);
  --color-info: hsl(195, 60%, 44%);
  --color-error: hsl(9, 71%, 48%);

  /* Fonts */
  --font-sans-serif: 'Inter', sans-serif;
  --font-serif: 'Playfair Display', serif;

  /* Font sizes */
  --font-size-small: 0.85rem;
  --font-size-smaller: 0.95rem;
  --font-size-normal: 1rem;
  --font-size-medium: 1.2rem;
  --font-size-large: 2rem;

  /* Font weights */
  --font-weight-bold: 600;

  /* Line heights */
  --line-height-normal: 1.6;

  /* Spacings */
  --main-col-spacing-top: clamp(10rem, 20vw, 15rem);
  --main-col-spacing-bottom: clamp(2rem, 5vw, 5rem);
  --block-spacing-horizontal-small: clamp(1rem, 3.5vw, 2.5rem);
  --block-spacing-horizontal-normal: clamp(2rem, 5vw, 5rem);
  --block-spacing-vertical-small: clamp(1rem, 2.5vw, 1.2rem);
  --block-spacing-vertical-normal: clamp(1rem, 5vw, 2rem);

  /* Forms */
  --input-spacing: 1.5em;

  --header-size: 0;
  --player-size: 30vw;
  --mini-player-size: 60px;
  --bottom-nav-size: 60px;

  @media (min-width: config.$bp-desktop) {
    --header-size: 20vw;
    --main-col-spacing-top: clamp(3rem, 15vw, 10rem);
  }

  &.theme-dark {
    --color-primary: hsl(20, 85%, 48%);
    --color-body-text: hsl(0, 0%, 85%);
    --color-body-bg: hsl(0, 0%, 10%);
    --color-surface-bg: hsl(0, 0%, 11%);
    --color-border-light: hsl(0, 0%, 20%);
    --color-border-normal: hsl(0, 0%, 30%);
  }
}

* {
  &:focus-visible {
    outline-color: var(--color-primary);
  }
}

html {
  font-family: var(--font-sans-serif);
  font-size: 15px;
  scrollbar-color: var(--color-primary) transparent;
  scrollbar-width: thin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 300px;
  color: var(--color-body-text);
  background: var(--color-body-bg);
  scrollbar-color: var(--color-primary) transparent;
  scrollbar-width: thin;
}

.bartender-bar {
  color: inherit;
  background: var(--color-surface-bg);
  scrollbar-color: var(--color-primary) transparent;
  scrollbar-width: thin;
}

h1 {
  @extend .break-word;
  font-family: var(--font-serif);
  font-size: clamp(2.4rem, 8vw, 3.5rem);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
  margin: 1em 0 0.5em 0;

  @media (min-width: config.$bp-desktop) {
    font-size: clamp(2.4rem, 4vw, 3.5rem);
  }

  &:first-child {
    margin-top: 0;
  }
}

h2,
.h2 {
  @extend .break-word;
  font-family: var(--font-serif);
  font-size: clamp(1.8rem, 2.5vw, 2.5rem);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  margin: 1.5em 0 1em 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--subtitle {
    font-size: clamp(1.6rem, 2.5vw, 1.8rem);
  }
}

h3 {
  @extend .break-word;
  font-family: var(--font-sans-serif);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-normal);
  line-height: var(--line-height-normal);
  margin: 1.5em 0 1.5em 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

p,
ul,
ol {
  margin: 0 0 1em 0;
  font-size: var(--font-size-normal);
  line-height: var(--line-height-normal);

  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  display: block;
  width: 1em;
  height: 1em;
}

label {
  display: block;
  margin-bottom: 0.5em;
}

nav {
  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  background: transparent;
  cursor: pointer;
}

.main-col-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  min-height: 100dvh;

  @media (min-width: config.$bp-desktop) {
    flex-direction: row;
  }
}

.confirm-modal {
  @include mixins.shadow();
  --max-width: 400px;
  text-align: center;

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: var(--block-spacing-vertical-normal);
    padding: var(--block-spacing-horizontal-normal);
  }

  &__title {
    display: block;
    font-size: var(--font-size-medium);
    margin: 0;
  }

  &__button-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
}

/* .background-image {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  background: var(--color-body-bg);

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top right;
    opacity: 0.1;
    mix-blend-mode: hard-light;
    filter: grayscale(0.75) blur(15px);
  }

  &:after {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    background: linear-gradient(transparent 50%, var(--color-body-bg) 80%);
  }
} */
</style>
