<template>
  <BartenderBar
    class="mobile-player-bar"
    name="mobile-player"
    position="bottom"
    @before-open="resetContentTransform"
    @after-close="resetContentTransform"
  >
    <template #default="{ close }">
      <div
        :class="[
          'mobile-player-bar__wrap',
          contentTransformResetting === true
            ? 'mobile-player-bar__content--resetting'
            : null,
        ]"
        :style="{
          transform: `translateY(${contentTransformY}px)`,
        }"
      >
        <span ref="swipeHandle" class="swipe-handle">
          <span class="swipe-handle__line" />
        </span>
        <div class="mobile-player-bar__content">
          <Player @tag-click="close()" />
        </div>
      </div>
      <PanelCloseButton @click="close" />
    </template>
  </BartenderBar>
</template>

<script setup lang="ts">
import { ref, useTemplateRef, nextTick } from 'vue'
import { useSwipe } from '@vueuse/core'
import { useBartender } from '@fokke-/vue-bartender.js'
import { BartenderBar } from '@fokke-/vue-bartender.js'

const bartender = useBartender()
const swipeHandleEl = useTemplateRef<HTMLDivElement>('swipeHandle')
const contentTransformY = ref<number>(0)

const { lengthY } = useSwipe(swipeHandleEl, {
  passive: false,
  threshold: 0,
  onSwipe() {
    if (contentTransformResetting.value === true) {
      return
    }

    const transformY = lengthY.value * -1
    if (transformY <= 0) {
      contentTransformY.value = 0
      return
    }

    contentTransformY.value = lengthY.value * -1
  },
  onSwipeEnd() {
    const transformY = lengthY.value * -1

    if (transformY >= 150) {
      bartender?.close('mobile-player')
      return
    }

    resetContentTransform()
  },
})

const contentTransformResetting = ref<boolean>(false)
const resetContentTransform = async () => {
  if (contentTransformY.value === 0) {
    return
  }

  contentTransformResetting.value = true
  await nextTick()
  contentTransformY.value = 0
  await new Promise((resolve) => setTimeout(resolve, 200))
  contentTransformResetting.value = false
}
</script>

<style lang="scss" scoped>
.mobile-player-bar {
  --height: 100dvh;
  --height-fallback: 100dvh;
  --max-height: 100dvh;
  --max-height-fallback: 100vh;
  background: transparent;

  // Hide on desktop
  @media (min-width: (config.$bp-desktop)) {
    display: none;
  }

  &__wrap {
    @include mixins.shadow('up');
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh;
    height: 100dvh;
    background: var(--color-surface-bg);
    scrollbar-color: var(--color-primary) transparent;
    scrollbar-width: thin;

    &--resetting {
      transition: transform 200ms ease;
    }
  }

  &__content {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    flex: 1 1 auto;
  }

  &__footer {
    z-index: 2;
    position: sticky;
    bottom: 0;

    button {
      position: absolute;
      right: 1.5rem;
      top: -1.5rem;
    }
  }
}

.swipe-handle {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
  padding: 2rem 0;

  &__line {
    display: block;
    width: 100%;
    max-width: 100px;
    height: 0.5em;
    background: var(--color-border-light);
    border-radius: 10em;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
}
</style>
