<template>
  <div v-if="!!slots.default" class="form-content">
    <slot name="default" />
  </div>
</template>

<script setup lang="ts">
const slots = defineSlots<{
  default(): any
}>()
</script>

<style lang="scss" scoped>
.form-content {
  position: relative;
  flex: 0 0 100%;
}
</style>
