import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from '@/App.vue'
import router from '@/router'
import { config } from '@/lib/config'
import { enUS } from '@/i18n/enUS'
import { fiFI } from '@/i18n/fiFI'
import { createBartender } from '@fokke-/vue-bartender.js'
import { registerSW } from 'virtual:pwa-register'

// Register service worker
registerSW({
  onRegisteredSW(swUrl, r) {
    if (!r) {
      return
    }

    // Check updates hourly
    setInterval(
      async () => {
        if (!(!r.installing && navigator)) return

        if ('connection' in navigator && !navigator.onLine) return

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache',
          },
        })

        if (resp?.status === 200) await r.update()
      },
      60 * 60 * 1000,
    )
  },
})

type MessageSchema = typeof enUS

const i18n = createI18n<[MessageSchema], 'fi-FI' | 'en-US'>({
  legacy: false,
  locale: (() => {
    if (config.value.locale === 'fi') {
      return 'fi-FI'
    }

    if (config.value.locale === 'en') {
      return 'en-US'
    }

    return config.value.locale || 'en-US'
  })(),
  fallbackLocale: 'en-US',
  messages: {
    'en-US': enUS,
    'fi-FI': fiFI,
  },
  datetimeFormats: {
    'en-US': {
      time: {
        hour: '2-digit',
        minute: '2-digit',
      },
    },
    'fi-FI': {
      time: {
        hour: '2-digit',
        minute: '2-digit',
      },
    },
  },
})

const bartender = createBartender()

createApp(App).use(i18n).use(router).use(bartender).mount('#app')
