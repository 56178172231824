<template>
  <form v-if="!!slots.default" class="form" @submit.prevent>
    <slot name="default" />
  </form>
</template>

<script setup lang="ts">
const slots = defineSlots<{
  default(): any
}>()
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}
</style>
