<template>
  <p class="summary">
    <slot />
  </p>
</template>

<style lang="scss" scoped>
.summary {
  font-family: var(--font-serif);
  font-size: var(--font-size-medium);
  margin-bottom: 3em;
}
</style>
