<template>
  <nav
    :class="[
      'mainNav',
      props.horizontal === true ? 'mainNav--horizontal' : 'mainNav--vertical',
    ]"
    aria-label="Main navigation"
  >
    <ul class="mainNav__list">
      <li v-for="item of routesToRender" :key="item.path" class="mainNav__item">
        <a
          :class="[
            'mainNav__link',
            item.name === route.name ||
            route.matched.some((match) => match.name === item.name)
              ? 'mainNav__link--current'
              : undefined,
          ]"
          :href="rootRoutePaths[String(item.name)] || String(item.path)"
          :aria-current="item.name === route.name ? 'page' : undefined"
          @click.prevent="onLinkClick(String(item.name))"
        >
          <i-mdi-star v-if="item.name === 'favourites'" class="mainNav__icon" />
          <i-mdi-search
            v-else-if="item.name === 'search'"
            class="mainNav__icon"
          />
          <i-mdi-book-open-page-variant
            v-else-if="item.name === 'browse'"
            class="mainNav__icon"
          />
          <span class="mainNav__text">
            {{ $t(`routes.${String(item.name)}.menuTitle`) }}
          </span>
        </a>
      </li>
      <li v-if="props.renderSettings === true">
        <button
          type="button"
          class="mainNav__link"
          v-bartender-open="'settings'"
        >
          <i-mdi-cog class="mainNav__icon" />
          <span class="mainNav__text">
            {{ $t('settings.title') }}
          </span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { rootRoutePaths } from '@/router'

const route = useRoute()
const router = useRouter()

const props = withDefaults(
  defineProps<{
    /** Use horizontal direction? */
    horizontal?: boolean

    /** Render settings button? */
    renderSettings?: boolean
  }>(),
  {
    horizontal: false,
    renderSettings: false,
  },
)

const routesToRender = computed(() => {
  return router.options.routes.filter((item) => {
    return item.meta?.hidden !== true
  })
})

const onLinkClick = (name: string) => {
  if (name === route.name) {
    return
  }

  if (rootRoutePaths.value[name]) {
    router.push({ path: rootRoutePaths.value[name] })
    return
  }

  router.push({ name })
}
</script>

<style lang="scss" scoped>
.mainNav {
  font-weight: var(--font-weight-normal);

  &--horizontal {
    margin-top: -1px;
    margin-bottom: env(safe-area-inset-bottom, 0);
  }

  &--vertical {
    margin-right: -1px;
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;

    .mainNav--vertical & {
      flex-direction: column;
      /* font-size: var(--font-size-medium); */
      text-align: right;
    }

    .mainNav--horizontal & {
      flex-direction: row;
      justify-content: center;
      height: 100%;
    }
  }

  &__item {
    display: block;
  }

  &__link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;
    transition: border-color 200ms ease;
    border-width: 0;
    border-style: solid;
    border-color: transparent;

    &--current {
      font-weight: var(--font-weight-bold);
      border-color: var(--color-primary);
    }

    .mainNav--horizontal & {
      flex-direction: column;
      padding: 0.75rem 1rem;
      border-top-width: 1px;
    }

    .mainNav--vertical & {
      flex-direction: row;
      width: 100%;
      padding: 1rem var(--block-spacing-horizontal-small);
      border-right-width: 1px;
      text-underline-offset: 0.4em;

      &:hover,
      &:focus-visible {
        text-decoration: underline;
        text-decoration-color: var(--color-primary);
      }
    }
  }

  &__icon {
    font-size: 1.2em;

    .mainNav--vertical & {
      display: none;
    }
  }

  &__text {
    display: block;
    line-height: 1;

    .mainNav--vertical & {
      flex: 1 1 auto;
    }

    .mainNav--horizontal & {
      font-size: var(--font-size-small);
    }
  }
}
</style>
