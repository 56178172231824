// import type { RouteRecordRaw } from 'vue-router'
// import type { Category } from '@/types'
import { ref, nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
// import categoryCache from '@/.cache/categories.json'
import FavouritesView from '@/views/FavouritesView.vue'
import SearchView from '@/views/SearchView.vue'
// import BrowseView from '@/views/BrowseView.vue'
// import CategoryContainerView from '@/views/CategoryContainerView.vue'
// import CategoryView from '@/views/CategoryView.vue'
import Error404View from '@/views/Error404View.vue'

export const rootRoutePaths = ref<Record<string, string>>({})
export const scrollPositions: Record<string, number> = {}

// const parseCategoryRoutes = (items: Category[]): RouteRecordRaw[] => {
//   return items.reduce((acc, category) => {
//     acc.push({
//       name: category.name,
//       path: category.name,
//       component: CategoryView,
//       children: parseCategoryRoutes(category.items),
//       meta: {
//         title: category.title,
//         image: category.image,
//       },
//     })

//     return acc
//   }, [] as RouteRecordRaw[])
// }

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'favourites',
      component: FavouritesView,
    },
    {
      path: '/search',
      name: 'search',
      component: SearchView,
    },
    // {
    //   path: '/browse',
    //   name: 'browse',
    //   component: BrowseView,
    //   children: [
    //     {
    //       path: 'category',
    //       name: 'categoryContainer',
    //       component: CategoryContainerView,
    //       // redirect: {
    //       //   name: 'browse',
    //       // },
    //       children: parseCategoryRoutes(categoryCache.items),
    //     },
    //   ],
    // },
    {
      path: '/:pathMatch(.*)*',
      name: 'error404',
      component: Error404View,
      meta: {
        hidden: true,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name) {
    // Store root route path
    const rootRouteName = String(to.matched[0].name)
    if (rootRouteName) {
      rootRoutePaths.value[rootRouteName] = to.path
    }
  }

  if (from.name) {
    // Store scroll position
    scrollPositions[String(from.name)] = window.scrollY
  }

  next()
})

router.afterEach(async (to) => {
  await nextTick()

  if (to.name) {
    // Restore scroll position
    window.scroll(0, scrollPositions[String(to.name)] || 0)

    // Update canonical meta
    document
      .querySelector('link[rel="canonical"]')
      ?.setAttribute('href', `https://viritin.fi${to.path}`)
  }
})

export default router
