<template>
  <div
    ref="el"
    :class="[
      'block',
      `block--align-${props.align}`,
      props.spacingX ? `block--spacing-x-${props.spacingX}` : null,
      props.spacingY ? `block--spacing-y-${props.spacingY}` : null,
      props.bordered === true ? `block--bordered` : null,
      props.fullWidth === true ? `block--full-width` : null,
    ]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    align?: 'left' | 'center' | 'right'
    spacingX?: 'none' | 'small' | 'normal'
    spacingY?: 'none' | 'small' | 'normal'
    bordered?: boolean
    fullWidth?: boolean
  }>(),
  {
    align: 'left',
    spacingX: 'normal',
    spacingY: 'normal',
    bordered: false,
    fullWidth: true,
  },
)

import {
  useTemplateRef,
  inject,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
} from 'vue'
import { useElementBounding } from '@vueuse/core'
import {
  registerBlockInjectionKey,
  deregisterBlockInjectionKey,
} from '@/lib/injection-keys'

const el = useTemplateRef<HTMLDivElement>('el')
const { height } = useElementBounding(el)
const registerBlock = inject(registerBlockInjectionKey, undefined)
const deregisterBlock = inject(deregisterBlockInjectionKey, undefined)

onMounted(() => {
  if (registerBlock) {
    const instance = getCurrentInstance()
    if (instance) {
      registerBlock(instance.uid, height)
    }
  }
})

onBeforeUnmount(() => {
  if (deregisterBlock) {
    const instance = getCurrentInstance()
    if (instance) {
      deregisterBlock(instance.uid)
    }
  }
})

defineExpose({
  height,
})
</script>

<style lang="scss" scoped>
.block {
  --align-items: flex-start;
  --width: unset;
  --padding-top: unset;
  --padding-bottom: unset;
  --padding-left: unset;
  --padding-right: unset;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: var(--align-items);
  width: var(--width);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);

  &--align {
    &-left {
      --align-items: flex-start;
    }

    &-center {
      --align-items: center;
    }

    &-right {
      --align-items: flex-end;
    }
  }

  &--spacing-x {
    &-none {
      --padding-left: 0;
      --padding-right: 0;
    }

    &-small {
      --padding-left: var(--block-spacing-horizontal-small);
      --padding-right: var(--block-spacing-horizontal-small);
    }

    &-normal {
      --padding-left: var(--block-spacing-horizontal-normal);
      --padding-right: var(--block-spacing-horizontal-normal);
    }
  }

  &--spacing-y {
    &-none {
      --padding-top: 0;
      --padding-bottom: 0;
    }

    &-small {
      --padding-top: var(--block-spacing-vertical-small);
      --padding-bottom: var(--block-spacing-vertical-small);
    }

    &-normal {
      --padding-top: var(--block-spacing-vertical-normal);
      --padding-bottom: var(--block-spacing-vertical-normal);
    }
  }

  // First child in padded main col will use special spacing
  &:first-child {
    .main-col--padded & {
      --padding-top: var(--main-col-spacing-top);
    }
  }

  // First child in padded main col will use special spacing
  &:last-child {
    .main-col--padded & {
      --padding-bottom: var(--main-col-spacing-bottom);
    }
  }

  &--bordered {
    border-top: solid 1px var(--color-border-light);
  }

  &--full-width {
    --width: 100%;
  }
}
</style>
