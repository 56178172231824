<template>
  <figure
    :class="[
      'station-image',
      `station-image--size-${props.size}`,
      props.image ? 'station-image--has-image' : 'station-image--no-image',
    ]"
  >
    <img
      v-if="props.image"
      class="station-image__image"
      :src="props.image.url"
      :width="props.image.width"
      :height="props.image.height"
      loading="lazy"
      alt=""
    />

    <div
      v-if="props.playing || props.loading || !props.image"
      class="station-image__overlay"
    >
      <Spinner
        v-if="props.loading"
        class="station-image__spinner"
        color="white"
        size="normal"
      />
      <i-mdi-play v-else-if="props.playing" class="station-image__playIcon" />
      <i-mdi-broadcast v-else-if="!props.image" />
    </div>
  </figure>
</template>

<script setup lang="ts">
import type { Image } from '@/types'

const props = withDefaults(
  defineProps<{
    /** Image */
    image?: Image

    /** Size of the image */
    size?: 'small' | 'medium' | 'large'

    /** Show play icon over the image? */
    playing?: boolean

    /** Show loading spinner over the image? */
    loading?: boolean
  }>(),
  {
    image: undefined,
    size: 'medium',
    playing: false,
    loading: false,
  },
)
</script>

<style lang="scss" scoped>
.station-image {
  position: relative;
  overflow: hidden;
  margin: 0;
  border-radius: 0.1em;
  background-color: #fff;
  @include mixins.shadow();

  &--size {
    &-small {
      width: 40px;
      height: 40px;
      font-size: 1.33em;
    }

    &-medium {
      width: 60px;
      height: 60px;
      font-size: 2em;
    }

    &-large {
      width: 120px;
      height: 120px;
      font-size: 4em;
    }
  }

  &--no-image {
    background-color: var(--color-primary);
    border: solid 1px var(--color-border-light);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__overlay {
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: var(--color-primary-transparent);
  }
}
</style>
