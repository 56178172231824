<template>
  <div class="player" tabindex="-1">
    <template v-if="config.currentStation">
      <Block spacing-x="small" spacing-y="small" align="center">
        <StationImage
          :image="config.currentStation.favicon"
          size="large"
          class="player__stationImage"
          :loading="isLoading"
        />
        <!-- <Button text="test" @click="triggerError" /> -->
      </Block>
      <Block spacing-x="small" spacing-y="small" align="center">
        <h2 v-html="config.currentStation.title" />

        <Notification
          v-if="hasError === true"
          :text="$t('player.error')"
          type="error"
        />
        <!-- <p v-else-if="nowPlaying">
          <strong v-if="nowPlaying.artist">
            {{ nowPlaying.artist }}
          </strong>
          <template v-if="nowPlaying.artist && nowPlaying.title"> - </template>
          {{ nowPlaying.title }}
        </p> -->
      </Block>
      <Block
        v-if="config.currentStation.tags.length"
        spacing-x="small"
        spacing-y="small"
        align="center"
      >
        <TagList
          v-model="config.currentStation.tags"
          justify="center"
          clickable
          @item-click="(item: Tag) => onTagClick(item)"
        />
      </Block>
      <Block spacing-x="small" align="center">
        <Button
          :text="
            isPlaying === true
              ? $t('player.controls.pause')
              : $t('player.controls.play')
          "
          :disabled="isLoading === true"
          size="large"
          rounded
          @click="isPlaying === true ? pause() : play()"
        >
          <template #icon>
            <i-mdi-pause v-if="isPlaying === true" />
            <i-mdi-play v-else />
          </template>
        </Button>
      </Block>
      <Block spacing-x="small" spacing-y="none" bordered align="center">
        <ActionBar>
          <ActionBarItem
            :text="
              favouriteStationIds.includes(config.currentStation.id)
                ? $t('favourites.remove')
                : $t('favourites.add')
            "
            @click="toggleFavouriteStation(config.currentStation)"
          >
            <template #icon>
              <i-mdi-star-off
                v-if="favouriteStationIds.includes(config.currentStation.id)"
              />
              <i-mdi-star v-else />
            </template>
          </ActionBarItem>
          <ActionBarItem :text="$t('share.title')" @click="share">
            <template #icon>
              <i-mdi-share-variant />
            </template>
          </ActionBarItem>
        </ActionBar>
      </Block>
      <Block
        v-if="showShareNotification === true"
        spacing-x="small"
        spacing-y="small"
        align="center"
      >
        <Notification
          :title="$t('share.notificationTitles.station')"
          :text="shareUrl"
        >
          <template #actions>
            <Button
              :text="$t('copy')"
              size="small"
              color="info"
              :disabled="copyShareUrlSuccessful"
              @click="copyShareUrl"
            >
              <template #icon>
                <i-mdi-check v-if="copyShareUrlSuccessful === true" />
                <i-mdi-content-copy v-else />
              </template>
            </Button>
          </template>
        </Notification>
      </Block>

      <Block v-if="currentPlaylist" spacing-x="small" bordered align="center">
        <List :aria-label="$t('player.playlist.title')" full-width>
          <ListItem
            v-for="(entry, index) of currentPlaylist"
            :key="index"
            :text="entry.combined"
            :clickable="false"
          >
            <!-- :footer="entry.date ? $d(entry.date, 'time') : undefined" -->
            <template #icon>
              <i-mdi-play v-if="index === 0" />
              <i-mdi-music v-else />
            </template>
            <template #actions>
              <Button
                :text="
                  $t('player.playlist.searchFromDiscogs', {
                    entry: entry.combined,
                  })
                "
                size="normal"
                hide-text
                rounded
                @click="searchFromDiscogs(entry.combined)"
              >
                <template #icon>
                  <i-mdi-database-search />
                </template>
              </Button>
            </template>
          </ListItem>
        </List>
      </Block>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Tag } from '@/types'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useFavouriteStations } from '@/lib/favouriteStations'
import { config } from '@/lib/config'
import {
  play,
  pause,
  isLoading,
  isPlaying,
  // nowPlaying,
  currentPlaylist,
  hasError,
  // triggerError,
} from '@/lib/player'
import { copy } from '@/lib/clipboard'

const emit = defineEmits<{
  'tag-click': [item: Tag]
}>()

const router = useRouter()
const { t } = useI18n()
const { favouriteStationIds, toggleFavouriteStation } = useFavouriteStations()

const onTagClick = (tag: Tag) => {
  router.push({
    name: 'search',
    query: { tag: tag.name },
    replace: router.currentRoute.value.name === 'search',
  })

  emit('tag-click', tag)
}

const searchFromDiscogs = (item: string | undefined) => {
  if (!item) {
    return
  }

  window.open(`https://www.discogs.com/search?q=${item}`, '_blank')
}

const shareUrl = computed<string | undefined>(() => {
  if (!config.value.currentStation) {
    return undefined
  }

  return `${location.protocol}//${location.host}?play=${config.value.currentStation.id}`
})

interface ShareData {
  title?: string
  text?: string
  url?: string
  files?: File[]
}

const showShareNotification = ref<boolean>(false)

const share = () => {
  if (!config.value.currentStation || !shareUrl.value) {
    return
  }

  copyShareUrlSuccessful.value = false

  const shareData: ShareData = {
    title: config.value.currentStation.title,
    text: t('share.texts.station', {
      stationName: config.value.currentStation.title,
    }),
    url: shareUrl.value,
  }

  if (!navigator.share || !navigator.canShare(shareData)) {
    showShareNotification.value = true
    return
  }

  try {
    navigator.share(shareData)
  } catch (e) {
    console.error(e)
    showShareNotification.value = true
  }
}

const copyShareUrlSuccessful = ref<boolean>(false)
const copyShareUrl = async () => {
  if (!shareUrl.value) {
    return
  }

  const result = await copy(shareUrl.value)

  if (result === false) {
    alert(t('clipboard.error'))
    return
  }

  copyShareUrlSuccessful.value = true

  setTimeout(() => {
    showShareNotification.value = false
  }, 1000)
}
</script>

<style lang="scss" scoped>
.player {
  width: 100%;
  text-align: center;
  outline: 0;
}
</style>
