<template>
  <div :class="['input', props.class]">
    <div
      v-if="props.label"
      :class="[
        'input__header',
        props.hideLabel === true ? 'sr-only' : undefined,
      ]"
    >
      <label v-if="props.label" :for="props.id">
        {{ props.label }}
      </label>
    </div>
    <div v-if="!!slots.default" class="input__content">
      <slot name="default" />
    </div>
    <div v-if="!!slots.footer" class="input__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type InputProps, inputPropsDefaults } from '@/composables/input'

interface InputBaseProps extends InputProps {
  id: string
  class: string
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<InputBaseProps>(), inputPropsDefaults)

const slots = defineSlots<{
  default(): any
  footer(): any
}>()
</script>

<style lang="scss" scoped>
.input {
  position: relative;
  flex: 0 0 100%;

  &__header,
  &__content,
  &__footer {
    position: relative;
  }
}
</style>
