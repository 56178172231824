<template>
  <Block>
    <div ref="searchResultsWrap" class="search-results" tabindex="-1">
      <Spinner
        v-if="stationsApi.loading.value === true"
        size="large"
        color="primary"
      />
      <template v-else-if="searchResponse">
        <Summary v-if="props.showSummary">
          {{ $t('searchForm.summary', searchResponse.total) }}
        </Summary>
        <StationList
          v-if="searchResponse?.items.length"
          :aria-label="$t('searchResults.title')"
        >
          <StationListItem
            v-for="item of searchResponse.items"
            :key="item.id"
            ref="stationListItemComponents"
            :station="item"
            @click="(station) => setStation(station)"
          />
        </StationList>
      </template>
    </div>
  </Block>
  <Block
    v-if="
      searchResponse?.total_pages && searchResponse.total_pages > currentPage
    "
  >
    <Button
      :text="$t('searchResults.loadMore')"
      :loading="stationsApi.busy.value === true"
      @click="currentPage++"
    />
  </Block>
</template>

<script setup lang="ts">
import type { SearchTerms, StationsResponse } from '@/types'
import { ref, useTemplateRef, computed, watch, nextTick } from 'vue'
import { setStation } from '@/lib/player'
import { useApi } from '@/lib/api'
import { watchPausable } from '@vueuse/core'
import StationListItem from '@/components/StationListItem.vue'

const props = withDefaults(
  defineProps<{
    q?: string
    tag?: string
    category?: string
    showSummary?: boolean
  }>(),
  {
    q: '',
    tag: '',
    category: '',
    showSummary: true,
  },
)

const searchResultsWrapEl = useTemplateRef<HTMLDivElement>('searchResultsWrap')
const currentPage = ref<number>(1)
const stationsApi = useApi({ silent: true })
const stationListItemComponents = useTemplateRef<
  InstanceType<typeof StationListItem>[]
>('stationListItemComponents')
const searchResponse = ref<StationsResponse | undefined>(undefined)

const reset = async () => {
  searchResponse.value = undefined

  pauseCurrentPageWatch()
  currentPage.value = 1
  await nextTick()
  resumeCurrentPageWatch()
}

const getResults = async (
  silent = false,
): Promise<StationsResponse | undefined> => {
  try {
    const response = await (stationsApi.request(
      'stations/',
      {
        params: {
          ...searchTerms.value,
          page: currentPage.value,
        },
      },
      {
        silent,
      },
    ) as Promise<StationsResponse>)

    return response
  } catch (error) {
    console.error(error)
    return undefined
  }
}

const search = async () => {
  await nextTick()
  await reset()

  // If there are no terms defined, bail out
  if (Object.values(searchTerms.value).every((item) => !item)) {
    reset()
    return
  }

  // Get results
  searchResponse.value = await getResults()
}

const searchTerms = computed<SearchTerms>(() => {
  return {
    q: props.q.trim(),
    tag: props.tag,
    category: props.category,
  }
})

watch(
  () => searchTerms.value,
  () => {
    search()
  },
  {
    immediate: true,
    deep: true,
  },
)

const { pause: pauseCurrentPageWatch, resume: resumeCurrentPageWatch } =
  watchPausable(
    () => currentPage.value,
    async () => {
      if (
        stationsApi.busy.value === true ||
        !searchResponse.value?.items.length
      ) {
        return
      }

      const nextPage = await getResults(true)
      if (!nextPage?.items?.length) {
        return
      }

      // Not sure why spread did not work here...
      searchResponse.value.items = searchResponse.value.items.concat(
        nextPage.items,
      )

      await nextTick()
      stationListItemComponents.value
        ?.find((item) => item.id === nextPage.items[0].id)
        ?.focus()
    },
  )

const focus = () => {
  searchResultsWrapEl.value?.focus()
}

defineExpose({
  focus,
})
</script>

<style lang="scss" scoped>
.search-results {
  width: 100%;
  outline: 0;
}
</style>
