<template>
  <div class="mini-player" tabindex="-1">
    <template v-if="config.currentStation">
      <div class="mini-player__col">
        <Button
          :text="
            isPlaying === true
              ? $t('player.controls.pause')
              : $t('player.controls.play')
          "
          :disabled="isLoading === true"
          size="medium"
          rounded
          @click="isPlaying === true ? pause() : play()"
        >
          <template #icon>
            <i-mdi-pause v-if="isPlaying === true" />
            <i-mdi-play v-else />
          </template>
        </Button>
      </div>
      <div class="mini-player__col" @click="expand">
        <StationImage
          :image="config.currentStation.favicon"
          size="small"
          class="mini-player__stationImage"
          :loading="isLoading"
        />
      </div>
      <div class="mini-player__col mini-player__col--fill" @click="expand">
        <Notification
          v-if="hasError === true"
          type="error"
          size="small"
          :text="$t('player.error')"
        />
        <template v-else>
          <h2 class="mini-player__title" v-html="config.currentStation.title" />
          <p class="mini-player__text" v-if="nowPlaying && nowPlaying.combined">
            {{ nowPlaying.combined }}
          </p>
        </template>
      </div>
      <div class="mini-player__col">
        <Button
          :text="$t('miniPlayer.expand')"
          hide-text
          size="normal"
          square
          @click="expand"
        >
          <template #icon>
            <i-mdi-arrow-expand-up />
          </template>
        </Button>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { config } from '@/lib/config'
import { useBartender } from '@fokke-/vue-bartender.js'
import {
  play,
  pause,
  isLoading,
  isPlaying,
  hasError,
  nowPlaying,
} from '@/lib/player'

const bartender = useBartender()

const expand = () => {
  bartender?.open('mobile-player')
}

defineExpose({
  expand,
})
</script>

<style lang="scss" scoped>
.mini-player {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  outline: 0;

  &__col {
    cursor: default;
    display: block;
    padding: 1rem 0;

    &--fill {
      flex: 1 1 auto;
    }
  }

  &__title,
  &__text {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: var(--font-sans-serif);
    font-size: var(--font-size-small);
    line-height: 1.2;
    margin: 0;
    max-height: calc((var(--font-size-small) * 1.2) * 2);
  }
}
</style>
