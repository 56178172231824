import type { Station } from '@/types'
import type { PlaylistEntry } from '@/lib/stream-metadata'
import { ref, computed } from 'vue'
import { config } from '@/lib/config'
import { createStatsListener, playlist } from '@/lib/stream-metadata'
import {
  setMediaSessionActionHandler,
  setMediaSessionMetadata,
  setMediaSessionPlaybackState,
} from '@/lib/media-session'
import { useApi } from '@/lib/api'
export { playlist } from '@/lib/stream-metadata'

const env = process.env
let audioEl: HTMLAudioElement

export const isLoading = ref<boolean>(false)
export const isPlaying = ref<boolean>(false)
export const isPaused = ref<boolean>(false)
export const hasError = ref<boolean>(false)
const playWhenLoaded = ref<boolean>(true)
const stationsApi = useApi()

export const play = () => {
  if (
    !audioEl ||
    !config.value.currentStation ||
    isPlaying.value === true ||
    isLoading.value === true
  ) {
    return
  }

  // If player was paused, go live
  if (isPaused.value === true) {
    setStation(config.value.currentStation)
    return
  }

  audioEl?.play()
}

export const pause = () => {
  if (
    !audioEl ||
    !config.value.currentStation ||
    isPlaying.value === false ||
    isPaused.value === true ||
    isLoading.value === true
  ) {
    return
  }

  audioEl?.pause()
}

export const triggerError = () => {
  audioEl.dispatchEvent(
    new ErrorEvent('error', {
      message: 'supsup',
    }),
  )
}

const init = (): HTMLAudioElement => {
  if (audioEl) {
    return audioEl
  }

  // Return new audio and listen events
  audioEl = new Audio()
  audioEl.setAttribute('preload', 'auto') // iOS 17

  audioEl.addEventListener('loadstart', () => {
    isLoading.value = true
    navigator.mediaSession.playbackState = 'none'
    setMediaSessionMetadata(config.value.currentStation)
    setMediaSessionPlaybackState('none')
  })

  audioEl.addEventListener('canplaythrough', () => {
    isLoading.value = false

    if (playWhenLoaded.value === false) {
      playWhenLoaded.value = true
      return
    }

    audioEl.play()
    return
  })

  audioEl.addEventListener('play', () => {
    isLoading.value = false
    isPlaying.value = true
    isPaused.value = false
    setMediaSessionPlaybackState('playing')
  })

  audioEl.addEventListener('playing', () => {
    isLoading.value = false
    isPlaying.value = true
    isPaused.value = false
    setMediaSessionPlaybackState('playing')
  })

  audioEl.addEventListener('pause', () => {
    isLoading.value = false
    isPlaying.value = false
    isPaused.value = true
    setMediaSessionPlaybackState('paused')
  })

  audioEl.addEventListener('waiting', () => {
    isLoading.value = true
  })

  audioEl.addEventListener('ended', () => {
    isPlaying.value = false
    isPaused.value = true
    setMediaSessionPlaybackState('none')
  })

  audioEl.addEventListener('error', (error) => {
    console.error('audio', error)
    audioEl.pause()
    isLoading.value = false
    isPaused.value = true
    hasError.value = true
    setMediaSessionPlaybackState('none')
  })

  // Set action handlers for media session
  setMediaSessionActionHandler('play', play)
  setMediaSessionActionHandler('pause', pause)
  setMediaSessionActionHandler('stop', pause)

  return audioEl
}

export const setStation = (station: Station, play: boolean = true): Station => {
  init()

  isPlaying.value = false
  isPaused.value = false
  hasError.value = false
  config.value.currentStation = station

  playWhenLoaded.value = typeof play !== 'undefined' ? play : true

  audioEl.currentTime = 0 // iOS 17
  audioEl.src = `${station.stream_url}?ts=${Date.now()}`
  audioEl.load() // iOS 17

  // Start fetching metadata
  createStatsListener(station)

  // Knock radio-browser station click counter
  if (station.uuid) {
    try {
      fetch(`https://de1.api.radio-browser.info/json/url/${station.uuid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'User-Agent': `Viritin.fi/${env.VUE_APP_VERSION}`,
        },
      })
    } catch (_e) {
      // Fail silently
    }
  }

  // Knock local station endpoint
  try {
    stationsApi.request(`stations/${station.name}/click/`)
  } catch (_e) {
    // Fail silently
  }

  return station
}

export const nowPlaying = computed<PlaylistEntry | undefined>(() => {
  if (!currentPlaylist.value || !config.value.currentStation) {
    return undefined
  }

  return playlist.value[config.value.currentStation.id][0]
})

export const currentPlaylist = computed<PlaylistEntry[] | undefined>(() => {
  return config.value.currentStation
    ? playlist.value[config.value.currentStation.id]
    : undefined
})
