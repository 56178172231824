<template>
  <List :align="props.align">
    <ListItem
      :text="config.darkMode === true ? $t('theme.light') : $t('theme.dark')"
      @click="config.darkMode = !config.darkMode"
    >
      <template #icon>
        <i-mdi-lightbulb-on v-if="config.darkMode === true" />
        <i-mdi-weather-night v-else />
      </template>
    </ListItem>
    <ListItem
      :text="$t(`locales.${nextLocale}`)"
      @click="config.locale = nextLocale"
    >
      <template #icon>
        <i-mdi-translate />
      </template>
    </ListItem>
  </List>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { config } from '@/lib/config'

const props = withDefaults(
  defineProps<{
    align?: 'left' | 'right'
  }>(),
  {
    align: 'left',
  },
)

const { locale } = useI18n()
const nextLocale = computed<string>(() => {
  return locale.value === 'en-US' ? 'fi-FI' : 'en-US'
})

watch(
  () => config.value.locale,
  (val) => {
    locale.value = val
  },
)
</script>
