import { globals } from './_globals'

export const enUS = {
  ...globals,
  shortName: 'en',
  edit: 'Edit',
  finish: 'Finish',
  country: 'Country',
  open: 'Open',
  close: 'Close',
  copy: 'Copy',
  copied: 'Copied',
  tryAgain: 'Try again',
  cancel: 'Cancel',
  remove: 'Remove',
  clearInput: 'Clear value of input "{label}"',
  logo: {
    ariaLabel: 'Back to home page',
  },
  breadcrumbs: {
    ariaLabel: 'Breadcrumbs',
  },
  updateNotification: {
    text: 'New version is available!',
    buttonText: 'Update',
  },
  notifications: {
    resolveStationFromUrlError:
      'Failed to find the requested station. The requested station may be moved or removed.',
  },
  settings: {
    title: 'Settings',
  },
  theme: {
    dark: 'Dark mode',
    light: 'Light mode',
  },
  favourites: {
    add: 'Add to favourites',
    remove: 'Remove from favourites',
    removeConfirm: 'Remove "{stationName}" from favourites?',
    resolveError: 'Failed to resolve your favourites.',
  },
  stationList: {
    ariaLabelDefault: 'Stations',
  },
  tagList: {
    ariaLabelDefault: 'Tags',
    removeHint: 'Click to remove',
  },
  actionBar: {
    ariaLabelDefault: 'Actions',
  },
  share: {
    title: 'Share',
    texts: {
      station: 'Listen to the radio station "{stationName}" on Viritin app!',
    },
    notificationTitles: {
      station: 'The following URL can be used to play this station',
    },
  },
  clipboard: {
    error: 'Failed to copy to clipboard. Please do it manually.',
  },
  searchForm: {
    title: 'Search for stations',
    query: {
      label: 'Search query',
      placeholder: 'Enter name, country or tag',
    },
    submit: {
      text: 'Search',
    },
    summary:
      'No stations found. | Found one station. | Found {count} stations.',
  },
  searchResults: {
    title: 'Search results',
    loadMore: 'Load more',
  },
  player: {
    buffering: 'Buffering...',
    error: 'Something went wrong!',
    controls: {
      play: 'Play',
      pause: 'Pause',
      volume: 'Volume',
    },
    nowPlaying: 'Now playing:',
    playlist: {
      title: 'Playlist',
      searchFromDiscogs: 'Search "{entry}" from Discogs',
    },
  },
  miniPlayer: {
    expand: 'Expand player view',
  },
  copyright: {
    providedBy: 'All station data is provided by',
    images:
      'All station logos and other data are copyright to their respective owners.',
  },
  routes: {
    favourites: {
      menuTitle: 'Favourites',
      title: 'Favourite stations',
      noItems: 'No favourite stations yet.',
    },
    search: {
      menuTitle: 'Search',
      title: 'Search for stations',
    },
    browse: {
      menuTitle: 'Browse',
      title: 'Browse stations',
    },
    categoryContainer: {
      menuTitle: 'Categories',
      title: 'Browse by category',
    },
    category: {
      menuTitle: 'Category',
      title: 'Category',
    },
    error404: {
      menuTitle: 'Page not found',
      title: 'Page not found',
      text: 'The requested page was not found. It may be removed or moved elsewhere.',
    },
  },
}
