import type { Station } from '@/types'
import { ref } from 'vue'
import IcecastMetadataStats from 'icecast-metadata-stats'
import { setMediaSessionMetadata } from '@/lib/media-session'

export interface IcyMetadata {
  StreamTitle?: string
}

export interface OggMetadata {
  ALBUM?: string
  ARTIST?: string
  ENCODER?: string
  TITLE?: string
  VENDOR_STRING?: string
}

export interface IcecastMetadataStatsResult {
  icy?: IcyMetadata
  ogg?: OggMetadata
}

export interface ParsedMetadata {
  title?: string
  artist?: string
  combined?: string
}

export interface PlaylistEntry extends ParsedMetadata {
  date: Date
}

export type Playlist = Record<number, PlaylistEntry[]>

export let statsListener: IcecastMetadataStats

export const playlist = ref<Playlist>([])

export const createStatsListener = (station: Station) => {
  statsListener?.stop()

  statsListener = new IcecastMetadataStats(station.stream_url, {
    interval: 30,
    sources: ['icy', 'ogg'],
    onStats: (stats: IcecastMetadataStatsResult) => {
      const parsedMetadata = ((): ParsedMetadata | undefined => {
        if (stats.icy) {
          return parseIcyMetadata(stats.icy)
        }

        if (stats.ogg) {
          return parseOggMetadata(stats.ogg)
        }

        return undefined
      })()

      if (!parsedMetadata) {
        statsListener?.stop()
        return
      }

      // In some cases returned metadata is returned, but all the values are empty.
      // When this occurs, we skip this round, but we don't stop the listener.
      if (!Object.values(parsedMetadata).some((val) => !!val)) {
        return
      }

      setMediaSessionMetadata(
        station,
        parsedMetadata.title,
        parsedMetadata.artist,
      )

      // Create a new list for this station
      if (!playlist.value[station.id]) {
        playlist.value[station.id] = []
      }

      // Push new entry, if it's different than the previous one
      if (
        !playlist.value[station.id].length ||
        playlist.value[station.id][0].combined !== parsedMetadata?.combined
      ) {
        playlist.value[station.id] = [
          // Math.floor(Date.now() / 1000)
          {
            date: new Date(),
            ...parsedMetadata,
          },
          ...playlist.value[station.id],
        ].slice(0, 9)
      }
    },
  })

  statsListener.start()
}

const stringToSentenceCase = (input?: string): string => {
  if (!input) {
    return ''
  }

  return input.toLowerCase().replace(/\w\S*/g, function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  })
}

const parseIcyMetadata = (input: IcyMetadata | undefined): ParsedMetadata => {
  const out: ParsedMetadata = {
    title: undefined,
    artist: undefined,
    combined: undefined,
  }

  if (!input || !input.StreamTitle) {
    return out
  }

  const segments = String(input.StreamTitle)
    .split(' - ')
    .map((str) => stringToSentenceCase(str.trim()))

  if (segments.length > 1) {
    out.artist = segments[0]
    out.title = segments.slice(1).join(' - ')
    out.combined = [out.artist, out.title].filter((item) => !!item).join(' - ')
  }

  return out
}

const parseOggMetadata = (input: OggMetadata | undefined): ParsedMetadata => {
  const out: ParsedMetadata = {
    title: undefined,
    artist: undefined,
    combined: undefined,
  }

  if (!input) {
    return out
  }

  out.title = stringToSentenceCase(input.TITLE)
  out.artist = stringToSentenceCase(input.ARTIST)
  out.combined = [out.artist, out.title].filter((item) => !!item).join(' - ')

  return out
}
