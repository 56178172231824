import { useId } from 'vue'

export interface InputProps {
  label: string | number
  hideLabel?: boolean
  class?: string
  disabled?: boolean
}

export const inputPropsDefaults: Omit<InputProps, 'label'> = {
  hideLabel: false,
  class: undefined,
  disabled: false,
}

export const useInput = () => {
  const id = useId()

  return {
    id,
  }
}
