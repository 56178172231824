import type { Station } from '@/types'
import { ref, watch } from 'vue'

export interface Config {
  locale: string
  darkMode: boolean
  currentStation?: Station
}

export const config = ref<Config>({
  locale: 'en-US',
  darkMode: window.matchMedia
    ? window.matchMedia('(prefers-color-scheme: dark)').matches
    : false,
  currentStation: undefined,
  ...JSON.parse(localStorage.getItem('viritinConfig') || '{}'),
})

// When config changes, save it to localStorage
watch(
  config,
  (val) => {
    if (typeof val !== 'object') {
      return
    }

    localStorage.setItem('viritinConfig', JSON.stringify(val))
  },
  {
    deep: true,
  },
)

watch(
  () => config.value.darkMode,
  (val) => {
    if (!document?.documentElement) {
      return
    }

    if (val === true) {
      document.documentElement.classList.add('theme-dark')
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', '#1a1a1a')
    } else {
      document.documentElement.classList.remove('theme-dark')
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', '#fbfaf9')
    }
  },
  {
    immediate: true,
  },
)
